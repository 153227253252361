import React from 'react';

export const teamList = [
  {
    image: "./image/team/sm/chase-sadacca_neg-saturation_430x573.png",
    image2: "./image/team/chase-sadacca_neg-saturation-1280-Black-BG-1.jpg",
    name: "CHASE",
    surname: "SADACCA",
    designation:"Co-Founder & CEO",
    twitterLink: "https://twitter.com/chaseisgr8",
    fbLink: "https://twitter.com/chaseisgr8",
    // linkedIn: "https://www.linkedin.com",
    // instagramLink: "https://www.instagram.com",
    email: "chase@nceptio.com",
    phone: "646.663.1122",

  },
  {
    image: "./image/team/sm/michael-newlin-neg-saturation-430x573.png",
    image2: "./image/team/michael-newlin_neg-saturation-1280-Black-BG-1.jpg",
    name: "MICHAEL",
    surname: "NEWLIN",
    designation:"Co-Founder & CTO",
    // twitterlink: "https://twitter.com",
    fbLink: "https://www.facebook.com/michael.newlin.94",
    linkedIn: "https://www.linkedin.com/in/mwnewlin/",
    // instagramLink: "https://www.instagram.com",
    email: "michael@nceptio.com",
    phone: "646.663.1122",
  },
  {
    image: "./image/team/sm/andreas-neumann_color_tp_430x573.png",
    image2: "./image/team/andreas-neumann-neg-saturation-1280-Black-BG-1.jpg",
    name: "ANDREAS",
    surname: "NEUMANN",
    designation:"Co-Founder & Chief Creative",
    // twitterlink: "https://twitter.com/chaseisgr8",
    fbLink: "https://www.facebook.com/neumanvision/",
    // linkedIn: "https://www.linkedin.com",
    instagramLink: "https://www.instagram.com/neumanvision/?hl=en",
    email: "dre@nceptio.com",
    phone: "646.663.1122",
  },
  {
    image: "./image/team/sm/uimaytan-neg-saturation-430x573.png",
    image2: "./image/team/uimaytan-neg-saturation-1280-black-BG-1.jpg",
    name: "UI MAY",
    surname: "TAN M.D.",
    designation:"President - NCEPTIO HEALTH",
    // twitterlink: "https://twitter.com/chaseisgr8",
    // fbLink: "https://twitter.com/chaseisgr8",
    linkedIn: "https://www.linkedin.com/in/dr-ui-may-tan-08341315/",
    // instagramLink: "https://www.instagram.com",
    email: "uimay@nceptio.com",
    phone: "646.663.1122",
  }
];

export const experienceOptions = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '10+',
    label: '10+',
  },
  {
    value: '15+',
    label: '15+',
  },
  {
    value: '20+',
    label: '20+',
  }
];