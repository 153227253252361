export const menuData = [
  {
    path: "about"
  },
  {
    path: "why1"
  },
  {
    path: "who"
  },
  {
    path: "core1"
  },
  {
    path: "core2"
  },
  {
    path: "core3"
  },
];

export const slides = [
  {
    id: 0,
    title: "Developers",
    url: "./image/developer.svg",
    icon: "./image/mouse.svg"
  },
  {
    id: 1,
    title: "Designers",
    url: "./image/designer.svg",
    icon: "./image/pencil.svg"
  },
  {
    id: 2,
    title: "Project Manager",
    url: "./image/project_manager.svg",
    icon: "./image/folder.svg"
  },
  {
    id: 3,
    title: "Data Scientist",
    url: "./image/Data_Scientist.svg",
    icon: "./image/graph.svg"
  },
  {
    id: 4,
    title: "Other Experts",
    url: "./image/nceptio-other-experts-group.svg",
    icon: "./image/question_mark.svg"
  }
];

export const management = [
  {
      img: './image/core/ManagementServices/management-services-team-management.svg',
      text: 'team',
  },
  {
      img: './image/core/ManagementServices/management-services-product-management.svg',
      text: 'product',
  },
  {
  img: './image/core/ManagementServices/management-services-project-management.svg',
      text: 'project',
  },
  {
      img: './image/core/ManagementServices/management-services-infrastructure-management.svg',
      text: 'infrastructure',
  }
]

export const design = [
  {
      img: './image/core/DesignServices/design-services-ui.svg',
      text: 'ui',
  },
  {
      img: './image/core/DesignServices/design-services-ux.svg',
      text: 'ux',
  },
  {
      img: './image/core/DesignServices/design-services-graphic-design.svg',
      text: 'graphic',
  },
  {
      img: './image/core/DesignServices/design-services-animation.svg',
      text: 'animation',
  }
]

export const businessAnalysis = [
  {
      img: './image/core/BusinessAnalysis/business-services-requirement-gathering.svg',
      text: 'requirement gathering',
  },
  {
      img: './image/core/BusinessAnalysis/business-services-workflows.svg',
      text: 'workflows',
  }
]

export const systems = [
  {
      img: './image/core/SystemServices/system-services-systems-architecture.svg',
      text: 'systems architecture',
  },
  {
      img: './image/core/SystemServices/system-services-cloud-technologies.svg',
      text: 'cloud technologies',
  },
  {
      img: './image/core/SystemServices/system-services-big-data-technologies.svg',
      text: 'big data technologies',
  }
]

export const onlineMarketing = [
  {
      img: './image/core/OnlineMarketingServices/online-marketing-services-websites.svg',
      text: 'website',        
  },
  {
      img: './image/core/OnlineMarketingServices/online-marketing-services-social-media-management.svg',
      text: 'social media management'
  },
  {
      img: '',
      text: 'seo',
  }
]

export const dataManagement = [
  {
      img: './image/core/DataManagementServices/data-management-services-strategy.svg',
      text: 'strategy'
  },
  {
      img: './image/core/DataManagementServices/data-management-services-implementation.svg',
      text: 'implementation'
  },
  {
      img: './image/core/DataManagementServices/data-management-services-storage.svg',
      text: 'storage'
  },
  {
      img: './image/core/DataManagementServices/data-management-services-pipelines.svg',
      text: 'pipelines'
  }
]

export const dataAnalysis = [
  {
      img: './image/core/DataAnalysisServices/data-analysis-services-data-science.svg',
      text: 'data science'
  },
  {
      img: './image/core/DataAnalysisServices/data-analysis-services-artificial-intelligence.svg',
      text: 'artificial intelligence'
  },
  {
      img: './image/core/DataAnalysisServices/data-analysis-services-machine-learning.svg',
      text: 'machine learning',
  },
  {
      img: './image/core/DataAnalysisServices/data-analysis-services-scientific-programming.svg',
      text: 'scientific programming'
  }
]

export const software = [ 
  {
      img: './image/core/SoftwareDevelopmentServices/software-development-services-full-stack-web-development.svg',
      text: 'full stack web'
  },
  {   
      img: './image/core/SoftwareDevelopmentServices/software-development-services-mobile-application-development.svg',
      text: 'mobile application'
  }
]
  
export const icons = {
  managementServices: {
      img : './image/core/management-services.svg'
  },
  designServices: {
      img: './image/core/design-services.svg' 
  },
  businessAnalysis: {
      img: './image/core/business-analysis.svg'
  },
  systemServices: {
      img: './image/core/system-services.svg'
  },
  marketingServices: {
      img: './image/core/marketing-services.svg'
  },
  dataManagement: {
      img: './image/core/data-management.svg'
  },
  dataAnalyis: {
      img: './image/core/data-analyis.svg'
  },
  softwareDevelopment: {
      img: './image/core/software-development.svg'
  },
  softwareService: {
      img: './image/core/software-as-a-service.svg'
  },
  productPartnership: {
      img: './image/core/product-partnership.svg'
  },
  productSupport: {
      img: './image/core/product-support-24-7.svg'
  }    
}
