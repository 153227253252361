import React from "react";
import posed from 'react-pose';
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import SplitText from 'react-pose-text';
import "./press.scss";
import Div100vh from 'react-div-100vh';
import SideMenu from '../SideMenu/SideMenu';

const Container = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 1 }
});

const charPoses = {
    exit: { opacity: 0, y: 0 },
    enter: {
        opacity: 1,
        y: 0,
        delay: ({ wordIndex }) => 1000 + wordIndex * 800
    }
};


class Press extends React.Component {

    constructor(props, context) {
        super(props, context);           
        this.state = {
          isMenuOpen: false,         
        };       
      }
    
  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen
    }))
  }

    render() {
        const { isMenuOpen } = this.state;
        const { pathname } = this.props.location;
        return (
            <Container>
                <SideMenu isOpen={isMenuOpen} toggleMenu={this.toggleMenu} pathname={pathname} history={this.props.history}/>
                <Header isOpen={isMenuOpen} title1="PRESS" page={pathname} toggleMenu={this.toggleMenu}/>
                <div className="container">
                    <Div100vh className="press-flex">
                        <h2>
                            <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
                                Coming Soon ...
                            </SplitText>
                        </h2>
                    </Div100vh>
                </div>
                <Footer />
            </Container>
        );
    }
}
export default Press;