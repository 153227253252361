import React, { useState, useEffect, useRef } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import axios from 'axios';
import * as Yup from 'yup';
import { FormHelperText } from '@material-ui/core';
import MySnackbarContentWrapper from '../../helpers/snackbar'
import Snackbar from '@material-ui/core/Snackbar';
import MuiPhoneNumber from "material-ui-phone-number";
import MenuItem from '@material-ui/core/MenuItem';
import uuid from "uuid";
import SkillSet from "./SkillSet";
import { experienceOptions } from "../../helpers/data";
import Recaptcha from "react-recaptcha";

import './HireForms.scss'

const HireDrawerForm = (props) => {

  const TEST_SITE_KEY = "6LclZ7MUAAAAAF7a7zq4nEJRJTQ938ab8LusbetL";
  const DELAY = 1500;

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackVariant, setSnackVariant] = React.useState('');
  const [snackMessage, setSnackMessage] = React.useState('');

  // const [callback, setCallback] = React.useState('not fired');
  // const [value, setValue] = React.useState('');
  // const [load, setLoad] = React.useState(false);
  // const [expired, setExpired] = React.useState(false);
  // const _reCaptchaRef = React.createRef();

  // const recaptchaInstance = React.createRef();

  // Dataset 
  let hireFreelancerData = {
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    experience: '',
    skillSets: '',
    message: '',
    recaptcha: '',
  };
  if (props.isFreelancerForm) {
    hireFreelancerData.skillSets = [];
    hireFreelancerData.experience = '';
  }

  // Validations
  let hireFreelancerValidation = Yup.object().shape({
    name: Yup.string().required('Please enter name'),
    lastName: Yup.string().required('Please enter lastName'),
    email: Yup.string().required('Please enter email').email(),
    experience: Yup.string().required('Please enter experience'),
    skillSets: (props.isFreelancerForm) ? Yup.string().required('Please enter skill sets') : Yup.string().notRequired(''),
    message: Yup.string().required('Please enter message'),
    recaptcha: Yup.string().required('The captcha is required'),
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  });

  // API PATH (Live : https://api.nceptio.com/process.php)
  const API_PATH = 'http://localhost/nceptio-website-api/hirefreelancer.php';

  // Handlers
  function handleSnackOpen() {
    setSnackOpen(true);
  }

  function handleSnackClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  }

  function TextFields(props) {
    return (
      <TextField InputProps={{ disableUnderline: true }} {...props} />
    );
  }

  return (
    <div>
      <Drawer anchor="bottom" open={props.bottom} onClose={props.drawerClose} className="black-gradient hire-drawer">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg
          className="drawer-form-width"
        >
          <Grid item sm={12} xs={12}
            className="drawer-title"
          >
            <h2 id="customized-dialog-title" className="customized-title-dialog customized-drawer-title">
              {props.dialogTitle}
            </h2>
          </Grid>

          <div className="footer-item">
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Formik
                  validationSchema={hireFreelancerValidation}
                  initialValues={hireFreelancerData}
                  onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                    console.log(values,'123');
                    let data = {
                      ...values,
                      isFreelancerForm: props.isFreelancerForm
                    }

                    axios({
                      method: 'POST',
                      url: `${API_PATH}`,
                      headers: { 'content-type': 'application/json' },
                      data: data
                    })
                      .then(result => {
                        setSnackVariant('success');
                        setSnackMessage('Message Sent Successfully');
                        handleSnackOpen()
                      })
                      .catch(error => {
                        setSnackVariant('error');
                        setSnackMessage('Please try again');
                        handleSnackOpen()
                      });
                    resetForm(hireFreelancerData)
                  }
                  }>
                  {({ handleSubmit, values, errors, touched, setFieldValue, load }) => (
                    <>
                      <Grid item
                        lg={4}
                        md={6}
                        sm={6}
                        xs={12}>
                        <TextFields
                          required
                          id="name-input"
                          fullWidth
                          label={props.isFreelancerForm ? "First Name" : "Name"}
                          type="text"
                          name="name"
                          variant="filled"
                          onChange={(e) => {
                            setFieldValue('name', e.target.value)
                          }}
                        />
                        {errors.name && touched.name && <FormHelperText error className="form-helper-text">{errors.name}</FormHelperText>}
                      </Grid>

                      {(props.isFreelancerForm) ?
                        <Grid item
                          lg={4}
                          md={6}
                          sm={6}
                          xs={12}>
                          <TextFields
                            required
                            id="name-input"
                            fullWidth
                            label="Last Name"
                            type="text"
                            name="lastName"
                            variant="filled"
                            onChange={(e) => {
                              setFieldValue('lastName', e.target.value)
                            }}
                          />
                          {errors.lastName && touched.lastName && <FormHelperText error className="form-helper-text">{errors.lastName}</FormHelperText>}
                        </Grid>
                        : ''
                      }

                      <Grid item
                        lg={4}
                        md={6}
                        sm={6}
                        xs={12}>
                        <TextFields
                          id="name-input"
                          fullWidth
                          label="Company Name"
                          type="text"
                          name="companyName"
                          variant="filled"
                          onChange={(e) => {
                            setFieldValue('companyName', e.target.value)
                          }}
                        />
                        {errors.companyName && touched.companyName && <FormHelperText error className="form-helper-text">{errors.companyName}</FormHelperText>}
                      </Grid>

                      <Grid
                        item

                        lg={props.isFreelancerForm ? 6 : 4}
                        md={6}
                        sm={6}
                        xs={12}

                      >
                        <TextFields
                          required
                          id="email-input"
                          fullWidth
                          label="Email"
                          type="email"
                          name="email"
                          autoComplete="email"
                          variant="filled"
                          className="email-field"
                          onChange={(e) => {
                            setFieldValue('email', e.target.value)
                          }}
                        />
                        {errors.email && touched.email && <FormHelperText error className="form-helper-text">{errors.email}</FormHelperText>}
                      </Grid>

                      <Grid
                        item
                        lg={6}
                        md={props.isFreelancerForm ? 9 : 6}
                        sm={props.isFreelancerForm ? 8 : 6}
                        xs={12}
                        className="phonenumber-field"
                      >
                        <MuiPhoneNumber
                          defaultCountry={'us'}
                          fullWidth
                          label="Phone Number"
                          name="phoneNumber"
                          variant="filled"
                          disableAreaCodes
                          onChange={(value) => {
                            setFieldValue('phoneNumber', value)
                          }}
                        />
                        {errors.phoneNumber && touched.phoneNumber && <FormHelperText error className="form-helper-text">{errors.phoneNumber}</FormHelperText>}
                      </Grid>

                      {(props.isFreelancerForm) ?
                        <Grid
                          item
                          lg={2}
                          md={3}
                          sm={4}
                          xs={12}
                        >
                          <TextField
                            required
                            label="Experience (years)"
                            select
                            variant="filled"
                            id="reddit-subject"
                            fullWidth
                            value={values.experience}
                            onChange={(e) => {
                              setFieldValue('experience', e.target.value)
                            }}
                          >
                            {experienceOptions.map(option => (
                              <MenuItem
                                key={uuid.v1()}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {errors.experience && touched.experience && <FormHelperText error className="form-helper-text">{errors.experience}</FormHelperText>}
                        </Grid>
                        : ''
                      }

                      <Grid
                        item

                        lg={props.isFreelancerForm ? 10 : 6}
                        md={12}
                        sm={12}
                        xs={12}
                        className="skill-select-box"
                      >
                        <SkillSet isFreelancerForm={props.isFreelancerForm}
                          name="skillSets"
                          onChange={(value) => {

                            setFieldValue('skillSets', value)
                          }}
                        />
                        {errors.skillSets && touched.skillSets && <FormHelperText error className="form-helper-text">{errors.skillSets}</FormHelperText>}
                      </Grid>

                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="message-textfield"
                      >
                        <TextFields
                          required
                          id="multiline-static"
                          fullWidth
                          // label="Provide sample of work"
                          label= {props.isFreelancerForm ? "Provide sample of work" : "Message"}
                          multiline
                          rows="4"
                          variant="filled"
                          onChange={(e) => {
                            setFieldValue('message', e.target.value)
                          }}
                        />
                        {errors.message && touched.message && <FormHelperText error className="form-helper-text">{errors.message}</FormHelperText>}
                      </Grid>

                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className="grid-captcha"
                      >

                        <div className="captcha">
                          <Recaptcha
                            sitekey={TEST_SITE_KEY}
                            render="explicit"
                            verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                            onloadCallback={() => { }}
                          />
                          {errors.recaptcha && touched.recaptcha && <FormHelperText error className="form-helper-text">{errors.recaptcha}</FormHelperText>}
                        </div>

                      </Grid>

                      <div className="footer-form-main drawer-footer-button">
                        <Fab
                          variant="extended"
                          color="default"
                          aria-label="Add"
                          className="btn-primary"
                          onClick={handleSubmit}
                          type="submit"
                        >
                          Submit
                        </Fab>

                        <Fab
                          variant="extended"
                          color="default"
                          aria-label="Add"
                          className="btn-primary"
                          onClick={props.drawerClose}
                        >
                          Cancel
                        </Fab>
                        <Snackbar
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          open={snackOpen}
                          autoHideDuration={5000}
                          onClose={handleSnackClose}
                          className="contact-snackbar"
                        >
                          <MySnackbarContentWrapper
                            onClose={handleSnackClose}
                            variant={snackVariant}
                            message={snackMessage}
                          />
                        </Snackbar>
                      </div>
                    </>
                  )}
                </Formik>
              </Grid>
            </form>
          </div>
        </Grid>
      </Drawer>

    </div>
  );
}
export default HireDrawerForm