import React from "react";
import posed from 'react-pose';
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import "./blog.scss";
import SideMenu from '../SideMenu/SideMenu';
import Swiper from "react-id-swiper";
import { Pagination } from "swiper/dist/js/swiper.esm";
import uuid from "uuid";
import BlogTabs from './BlogTabs';
import Breadcrumb from "./Breadcrumbs";

const BreadcrumbDetails = [
    {
      category: "SOCIAL",
      blogTitle: "HOW SOCIAL MEDIA IS CHANGING THE WAY YOUR KIDS THINK",
    }
  ]

const Container = posed.div({
    enter: { x: 0, opacity: 1 },
    exit: { x: 0, opacity: 1 }
});

const sliderDetails = [
    {
        img: "./image/blockchain-1306x800.jpg",
        text: "Social Media",
    },
    {
        img: "./image/entertainment-1306x800-alt.jpg",
        text: "Health Care",
    },
]

class BlogPost extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isMenuOpen: false,
            isBlog: true,
            isBlogPost: true,
        };
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            isMenuOpen: !prevState.isMenuOpen
        }))
    }

    render() {
        const params = {
            shouldSwiperUpdate: true,
            activeSlideKey: "1",
            loadPrevNext: false,
            modules: [Pagination],
            direction: "horizontal",
            // mousewheel: true,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true
            }
        };

        const { isMenuOpen, isBlog, isBlogPost } = this.state;
        const { pathname } = this.props.location;
        return (
            <Container>
                <SideMenu isOpen={isMenuOpen} toggleMenu={this.toggleMenu} pathname={pathname} history={this.props.history} />
                <Header isOpen={isMenuOpen} title1="THE" title2="SPARK" page={pathname} toggleMenu={this.toggleMenu} bgcolor={isBlog} />
                <div className="container blog-slider">
                    <Swiper {...params}>
                        {sliderDetails &&
                            sliderDetails.map((slide) => {
                                return (
                                    <div key={uuid.v1()}>
                                        <img src={slide.img} style={{ width: "100%" }} />
                                        <span className="slider-text"> {slide.text} </span>
                                    </div>
                                );
                            })}
                    </Swiper>
                    <div className="blog-content-area">
                        <div className="blog-details">
                            <Breadcrumb BreadcrumbCategory={BreadcrumbDetails[0].category} BreadcrumbTitle={BreadcrumbDetails[0].blogTitle} />                                                   
                        </div>
                    </div>                   
                </div>
                {/* <Footer /> */}
            </Container>
        );
    }
}
export default BlogPost;