import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: '1px 2px 10px 0px rgba(0,0,0,0.2)',
    borderRadius: '10px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    marginLeft: '10px',
  },
  cover: {
    width: '100%',
    borderRadius: '10px',
  },
  author: {
    marginTop: '10px',
    fontWeight: '500',
    display: 'flex',
    lineHeight: '40px',
  },
  blogContent: {
    fontFamily: 'Raleway-Medium',
    marginTop: '20px',
    // color: '#6C7A89',
  },
  title: {
    color: '#6C7A89',
    marginTop: '5px',
    textDecoration: 'underline'
    // "&:hover" : {
    // }
  },
  flexDir: {
    flexDirection: 'row-reverse',
  },
  categoryColor: {
    color: '#B1A1B0',    
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  avatar: {
    marginRight: '5px',
  },
  avatarImg: {
    height: '100%',
    width: '100%',
  },
}));

export default function MediaControlCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Box display="flex" flexDirection={props.isOdd ? "row" : "row-reverse"}>
        <CardMedia
          className={classes.cover}
          image={props.postImg}
          title={props.postTitle}
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>

            <Typography component="h6" variant="h6" className={classes.categoryColor}>
              {props.postCategory}
            </Typography>

            <Typography component="h5" variant="h5" className={classes.title} component="a" href="/BlogPost" target="_blank">
              {props.postTitle}
            </Typography>

            <Typography variant="subtitle1" className={classes.blogContent}>

              {props.postText}

            </Typography>

            <Typography variant="subtitle2" className={classes.author}>
              <Avatar className={classes.avatar}>
                <img src={props.postAuthorImage} className={classes.avatarImg} />
              </Avatar>
              {props.postAuthor}
            </Typography>
          </CardContent>
        </div>
      </Box>
    </Card>
  );
}