import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Truncate from 'react-truncate';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 'auto',
    // ['@media (min-width: 768px) and (max-width: 1024)'] : {
    //   maxWidth: 'auto',
    // }
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatarImg: {
    height: '100%',
    width: '100%',
  },
  blogContent: {
    marginTop: '5px',
    // color: '#6C7A89',
    fontSize: '2.7rem',
    fontFamily: 'Raleway-Medium',
  }, 
  title: {
    color: '#6C7A89',
    fontSize: '3.5rem',
    marginTop: '5px',
    textDecoration: 'underline'
    // "&:hover" : {
    // }
  },
  category: {
    fontWeight: 'bold',
    color: '#B1A1B0',
    fontSize: '2.5rem',    
  }
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image={props.postImg}
        title={props.postTitle}
      />
      <CardContent>
        <Typography component="h6" variant="h6" className={classes.category}>
          {props.postCategory}
        </Typography>
        <Typography component="h5" variant="h5" className={classes.title} component="a" href="/BlogPost" target="_blank">
          {props.postTitle}
        </Typography>
        <Typography variant="subtitle1" className={classes.blogContent}>
          <Truncate lines={2} ellipsis={<span>... <a href='/BlogPost' target="_blank">Read more</a></span>}>
            {props.postText}
          </Truncate>
        </Typography>
      </CardContent>

      <CardActions>
        <Avatar className={classes.avatar}>
          <img src={props.postAuthorImage} className={classes.avatarImg} />
        </Avatar>
        <Typography variant="subtitle1" className={classes.blogContent}>
          {props.postAuthor}
        </Typography>
      </CardActions>

    </Card>
  );
}
