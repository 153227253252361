import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    // maxWidth: 345,
  },
  media: {
    height: '150px',
    width: '150px',
    borderRadius: '50%',
    margin: '0 auto',
    ['@media (max-width:768px)'] : {
      height: 80,
      width: 80,
    }
  },
  titleFont: {
    fontSize: '1rem',
    color: '#FAFAEF',
    textTransform: 'uppercase',
    ['@media (max-width:767px)'] : {     
      fontSize: '2.3rem',      
  }
  },
  padding: {
    ['@media (max-width:767px)'] : {
      padding: '8px',
    }
}
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.serviceImage}
          title={props.serviceText}
        />
        <CardContent className={classes.padding}>
          <Typography gutterBottom variant="h5" component="h2" className={classes.titleFont}>
           {props.serviceText}
          </Typography>          
        </CardContent>
      </CardActionArea>      
    </Card>
  );
}