import React, { useState } from "react";
import { Transition, Trail, animated } from "react-spring/renderprops";
import Grid from "@material-ui/core/Grid";
import "react-animated-slider/build/vertical.css";

import IconButton from "@material-ui/core/IconButton";
import InstagramIcon from "mdi-react/InstagramIcon";
import TwitterIcon from "mdi-react/TwitterBoxIcon";
import LinkedinIcon from "mdi-react/LinkedinBoxIcon";
import FacebookIcon from "mdi-react/FacebookBoxIcon";

export class Slug extends React.PureComponent {
  render() {
    const {
      children,
      from = { opacity: 0, transform: "translate3d(0,0px,0)" },
      to = { opacity: 1, transform: "translate3d(0,0px,0)" },
      ...rest
    } = this.props;
    const result = React.Children.map(children, child => styles => {
      const Component = animated[child.type] || animated(child.type);
      const props = {
        ...child.props,
        style: {
          willChange: "opacity, transform",
          ...child.props.style,
          ...styles
        }
      };
      return <Component {...props} />;
    });
    return (
      <Trail
        native
        {...rest}
        items={result}
        keys={result.map((_, i) => i)}
        from={from}
        to={to}
        children={child => child}
      />
    );
  }
}

// Wrapper around react-springs Transition.
// It will Transition the child node in and out depending on the "show" prop.
export class SFade extends React.PureComponent {
  render() {
    const {
      children,
      show,
      from = { opacity: 0 },
      enter = { opacity: 1 },
      leave = { opacity: 0 },
      ...rest
    } = this.props;

    const { type, props } = children;
    const Component = animated[type] || animated(type);
    const result = styles => {
      const newProps = {
        ...props,
        style: {
          willChange: "opacity, transform",
          ...props.style,
          ...styles
        }
      };
      return <Component {...newProps} />;
    };

    return (
      <Transition
        native
        items={show}
        {...rest}
        from={from}
        enter={enter}
        leave={leave}
        children={show => show && result}
      />
    );
  }
}
