import React, { Component } from "react";
import Modal from "react-responsive-modal";
import "react-animated-slider/build/vertical.css";
import ScrollSnap from "scroll-snap";
import uuid from "uuid";
import posed from 'react-pose';
import { CSSTransition } from "react-transition-group";

import Footer from "../Footer/Footer";
import Header from "../header/Header";
import TeamFooter from "./TeamFooter";
import SideMenu from '../SideMenu/SideMenu';
import "./team.scss";
import { getChaseSlide, getAreasSlide, getMaySlide } from './TeamDetails'
import { teamList } from '../../helpers/data'
import Div100vh from "react-div-100vh/lib/Div100vh";
// import { calc } from "popmotion";
// import { scale } from "style-value-types";

import {
  isMobile
} from "react-device-detect";

const Container = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 1 }
});

const snapConfig = {
  scrollSnapDestination: "0% 100%",
  scrollTimeout: 300,
  scrollTime: 200
};

export default class team extends Component {
  // state = {
  //   isMenuOpen: false,
  //   open: false,
  //   openModal: false,
  //   openModalEffect: false,
  //   selectedTeamMember: null,
  //   left: 0,
  //   height: 0,
  //   top: 0,
  //   titleHeight : 0, 
  //   topHeaderHeight: 0, 
  //   headerHeight: 0, 
  //   topFooterHeight: 0, 
  // };

  team = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      isMenuOpen: false,
      open: false,
      openModal: false,
      openModalEffect: false,
      selectedTeamMember: null,
      left: '',
      height: '',
      top: '',
      titleHeight : 0, 
      topHeaderHeight: 0, 
      headerHeight: 0, 
      topFooterHeight: 0, 
    };
    this.menuData = [
      {
        path: "chase"
      },
      {
        path: "michael"
      },
      {
        path: "andreas"
      },
      {
        path: "may"
      }
    ];
    this.teamList = teamList
  }

  componentDidMount() {
    this.bindScrollSnap();
  }

  bindScrollSnap() {
    const callback = () => {
      const activeIndex = Math.round(
        this.team.current.scrollTop / this.team.current.offsetHeight
      );
      this.setState({ activeIndex });
    };
    const element = this.team.current;
    const snapObject = new ScrollSnap(element, snapConfig);
    snapObject.bind(callback);
  }

  getCoordinates = (ref) => {
    setTimeout(() => {
      const coords = ref && ref.getBoundingClientRect();
      if (coords) {
        this.setState({
          top: coords.top,
          left: coords.left,
          height: coords.height
        })
      }
    }, 1000)
  }

  //Header Height

  calculateHeaderHeight = (ref) => {  
    const coords = ref && ref.getBoundingClientRect();
    if (coords) {
      this.setState({
        topHeaderHeight: coords.height,
      })
    }
  }

  //Footer Height
  calculateFooterHeight = (ref) => {   
    // setTimeout(() => {  
    const coords = ref && ref.getBoundingClientRect();
    if (coords) {
      this.setState({
        topFooterHeight: coords.height,
      }, () => {      
      })
    } 
  // }, 500);   
  }

  // Title Height
  calculateTopHeader = (ref) => {    
    const coords = ref && ref.getBoundingClientRect();
    if (coords) {
      this.setState({
        headerHeight: coords.height,
      })
    }    
  }

  // Chase Top
  calculateTopHeight = (ref) => {    
    const coords = ref && ref.getBoundingClientRect();     
    if (coords) {
      this.setState({
        topTitleHeight: coords.top,
      })
    }
  }

  onEnteredModal = () => {
    this.setState({
      titleHeight : this.state.topTitleHeight
    })
  }
  
  onOpenModal = (memberIndex) => {      
    this.setState({ openModalEffect: true, selectedTeamMember: memberIndex }, () => {
      setTimeout(() => {
        this.setState({ open: true, openModal: true });
      }, 450);
    });
  }

  onCloseModal = () => {
    this.setState({ open: false, titleHeight: 0});
    setTimeout(() => {
      this.setState({ openModal: false, openModalEffect: false });
    }, 1000);
  }

  pagination = index => {
    this.setState({ activeIndex: index });
  }

  componentWillReceiveProps(nextProps) {
    this.previousView = this.props.location;
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen
    }))
  }


  render() {    
    const { location, history } = this.props;
    const { pathname } = location;
    const {activeIndex, open, openModal, openModalEffect, selectedTeamMember, isMenuOpen } = this.state;
    const translateValue = this.state.titleHeight - 20;
    return (
      <>
        <Container>
          <SideMenu isOpen={isMenuOpen} toggleMenu={this.toggleMenu} pathname={pathname} history={history} />
          <div className="container">
            <Header
              title1="OUR"
              title2="TEAM"
              page={pathname}
              modalState={openModalEffect}
              toggleMenu={this.toggleMenu}
              refFunction={this.calculateHeaderHeight}
            />
            <div className="team-content">
              <div className="weare-teem" ref={this.container}>
                <div className="intro-section" ref={this.calculateTopHeader}>
                  <h1
                    className={
                      this.state.openModalEffect
                        ? "intro-title title-animation"
                        : "intro-title fade-in"
                    }
                  >
                    24/7/365
                  </h1>
                </div>
                <div className="team" ref={this.team}>
                  <Div100vh className="paginateWrapper">
                    <ul>
                      {this.menuData &&
                        this.menuData.map((menuItem, index) => {
                          return (
                            <li
                              key={uuid.v1()}
                              className={index === activeIndex ? "active" : ""}
                              onClick={() => this.pagination(index)}
                            >
                              <a href={`#${menuItem.path}`} />
                            </li>
                          );
                        })}
                    </ul>
                  </Div100vh>
                  <div className="team-list" id="chase" >
                    <div className="team-name" onClick={() => this.onOpenModal(0)} >
                      <p ref={this.calculateTopHeight}>CHASE</p>
                    </div>              
                    <div className='team-image text-center'>
                      <img 
                        src={(isMobile) ? "./image/team/sm/chase-sadacca_neg-saturation_430x573.png" : "./image/team/chase-sadacca_neg-saturation-1280-Black-BG-1.jpg" }
                        style={{height: `calc(100vh - ${this.state.topHeaderHeight + this.state.headerHeight + this.state.topFooterHeight}px)`}} 
                        className="fade-in translate-img" 
                        onClick={() => this.onOpenModal(0)}
                      />                      
                    </div>
                  </div>
                  <div className="team-list" id="michael">
                    <div className="team-name" onClick={() => this.onOpenModal(1)}>
                      <p>MICHAEL</p>
                    </div>
                    <div className='team-image text-center'>
                      <img 
                        src={(isMobile) ? "./image/team/sm/michael-newlin-neg-saturation-430x573.png" : "./image/team/michael-newlin_neg-saturation-1280-Black-BG-1.jpg"}
                        style={{height: `calc(100vh - ${this.state.topHeaderHeight + this.state.headerHeight + this.state.topFooterHeight}px)`}} 
                        className="fade-in translate-img"
                        onClick={() => this.onOpenModal(1)} />
                    </div>
                  </div>
                  <div className="team-list" id="andreas">
                    <div className="team-name" onClick={() => this.onOpenModal(2)}>
                      <p>ANDREAS</p>
                    </div>
                    <div className='team-image text-center'>
                      <img
                        src={(isMobile) ? "./image/team/sm/andreas-neumann_color_tp_430x573.png" : "./image/team/andreas-neumann-neg-saturation-1280-Black-BG-1.jpg" }
                        style={{height: `calc(100vh - ${this.state.topHeaderHeight + this.state.headerHeight + this.state.topFooterHeight}px)`}} 
                        className="fade-in translate-img"
                        onClick={() => this.onOpenModal(2)} />
                    </div>
                  </div>
                  <div className="team-list" id="may">
                    <div className="team-name" onClick={() => this.onOpenModal(3)}>
                      <p>UI MAY</p>
                    </div>
                    <div className='team-image text-center'>
                      <img
                        src= {(isMobile) ? "./image/team/sm/uimaytan-neg-saturation-430x573.png" : "./image/team/uimaytan-neg-saturation-1280-black-BG-1.jpg"}
                        style={{height: `calc(100vh - ${this.state.topHeaderHeight + this.state.headerHeight + this.state.topFooterHeight}px)`}} 
                        className="fade-in translate-img"
                        onClick={() => this.onOpenModal(3)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer modalState={openModalEffect} refFooter={this.calculateFooterHeight}/>

            <CSSTransition timeout={0} classNames="modal" in={open}>
              <>
                {openModal && (<>
                  <Modal open={openModal} onClose={this.onCloseModal} onEntered={this.onEnteredModal} animationDuration={100}>
                    <>
                      <div className="team-modal-footer">
                        <div className="container modal-position">
                          <div className="team-content">
                            <div className="weare-teem">
                              <div className="team margin-top">
                                <div className="team-list">
                                  <div className="team-name">
                                    <p 
                                      className="title"
                                      ref={this.getCoordinates} 
                                      style={{ transform: `translateY(-${translateValue}px)` }}
                                    >{this.teamList[selectedTeamMember].name}</p>
                                  </div>
                                  <div className="team-image text-center">
                                    <img                                      
                                      src={(isMobile) ? this.teamList[selectedTeamMember].image : this.teamList[selectedTeamMember].image2}
                                      className="team-modal-image"
                                      style={{height: `calc(100vh - ${this.state.topHeaderHeight + this.state.headerHeight + this.state.topFooterHeight}px)`                                          
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="position-text" style={{
                            left: `${this.state.left}px`,
                            top: `calc(${this.state.top}px)`,
                          }}>
                            <p className="subtitle">{this.teamList[selectedTeamMember].surname} </p>
                          </div>
                          <div className="modal-content" style={{
                            top: `calc(${this.state.top + this.state.height + this.state.height}px)`,
                            left: `${this.state.left}px`,
                          }}
                          >
                            <h5 className="co-founder-text">{this.teamList[selectedTeamMember].designation}</h5>
                            {selectedTeamMember === 0 && getChaseSlide()}
                            {selectedTeamMember === 2 && getAreasSlide()}
                            {selectedTeamMember === 3 && getMaySlide()}
                            {}
                          </div>
                        </div>

                        <TeamFooter teamData={this.teamList[selectedTeamMember]} />
                      </div>
                    </>
                  </Modal>
                </>
                )}
              </>
            </CSSTransition>
          </div>
        </Container>
      </>
    );
  }
}
