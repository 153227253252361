import React, { Component } from "react";

// import ReactDOM from 'react-dom';
// import BurgerMenu from 'react-burger-menu';
import { Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
import Header from "../header/Header";
import Div100vh from 'react-div-100vh';


class MenuWrap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
            active: false,
            isOpenMenu: false,
        };
    }


    show() {
        this.setState({ hidden: false });
    }

    render() {
        let style;

        if (this.state.hidden) {
            style = { display: 'none' };
        }

        return (
            <div style={style} className={this.props.side}>
                {this.props.children}
            </div>
        );
    }
}

export default class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            side: 'left'
        };
        this.delayRedirect = this.delayRedirect.bind(this);
    }

    delayRedirect(event) {        
        setTimeout(() => {
            const { pathname } = this.props;
            if (pathname === '/' + this.state.active) {
                setTimeout(() => { this.props.toggleMenu(); this.setState({ active: '' }); }, 1000);
            } else {
                const { history } = this.props;
                event.preventDefault();
                setTimeout(() => history.push(`/${this.state.active}`), 1000);
            }
        }, 1000)
    }

    isMenuOpen = (flag) => {
        if (!flag.isOpen) {
            this.props.toggleMenu()
        } 
    }

    render() {
        const { active } = this.state;
        const { isOpen, toggleMenu, isOpenMenu } = this.props;

        return (
            <div id="outer-container" style={{ height: '100%' }}>
                <MenuWrap wait={20} side={this.state.side} />
                <Menu isOpen={isOpen} customBurgerIcon={false} noOverlay disableOverlayClick customCrossIcon={<img src="./image/close.svg" />} id={this.state.currentMenu} pageWrapId={'page-wrap'} outerContainerId={'outer-container'} width={'100%'}>
                    <div id="outer-container" className="container">
                        <Header isOpen={isOpen} toggleMenu={toggleMenu} />
                        <Div100vh className="menu mobile-landscape-menu">
                            <Link to="#" onClick={this.delayRedirect}>
                                <div
                                    className={active ? (active === 'about' ? "menu-item slide-about-bottom" : "menu-item fadeOut") : "menu-item"}
                                    onClick={() => this.setState({ active: 'about' })}
                                >
                                    ABOUT
                            </div>
                            </Link>
                            <Link to="#" onClick={this.delayRedirect} >
                                <div
                                    className={active ? (active === 'work' ? "menu-item slide-work-bottom" : "menu-item fadeOut") : "menu-item"}
                                    onClick={() => this.setState({ active: 'work' })}
                                >
                                    WORK
                            </div>
                            </Link>
                            <Link to="#" onClick={this.delayRedirect}>
                                <div
                                    className={active ? (active === 'services' ? "menu-item slide-service-bottom" : "menu-item fadeOut") : "menu-item"}
                                    onClick={() => this.setState({ active: 'services' })}
                                >
                                    SERVICES
                            </div>
                            </Link>
                            <Link to="#" onClick={this.delayRedirect}>
                                <div
                                    className={active ? (active === 'team' ? "menu-item slide-team-bottom" : "menu-item fadeOut") : "menu-item"}
                                    onClick={() => this.setState({ active: 'team' })}
                                >
                                    TEAM
                            </div>
                            </Link>
                            <Link to="#" onClick={this.delayRedirect}>
                                <div
                                    className={active ? (active === 'blog' ? "menu-item slide-blog-bottom" : "menu-item fadeOut") : "menu-item"}
                                    onClick={() => this.setState({ active: 'blog' })}
                                >
                                    BLOG
                            </div>
                            </Link>
                            <Link to="#" onClick={this.delayRedirect}>
                                <div
                                    className={active ? (active === 'contact' ? "menu-item slide-contact-bottom" : "menu-item fadeOut") : "menu-item"}
                                    onClick={() => this.setState({ active: 'contact' })}
                                >
                                    CONTACT
                            </div>
                            </Link>
                        </Div100vh>
                    </div>
                </Menu>
            </div>
        );
    }
}

