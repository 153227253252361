import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Select from 'react-select';
import { emphasize, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';

const suggestions = [
  { label: 'NET' },
  { label: 'ABAP' },
  { label: 'ActionScript' },
  { label: 'Ada' },
  { label: 'ADF' },
  { label: 'ADK' },
  { label: 'AdMob' },
  { label: 'Adobe After Effects Designers' },
  { label: 'Adobe Air' },
  { label: 'Adobe CQ' },
  { label: 'Adobe Creative Suite Experts' },
  { label: 'Adobe Illustrator Experts' },
  { label: 'Adobe InDesign Experts' },
  { label: 'Adobe Software Engineers' },
  { label: 'Adobe XD Designers' },
  { label: 'Agavi' },
  { label: 'Agile' },
  { label: 'Agile Coaches' },
  { label: 'Agile Consultants' },
  { label: 'Agile Program Managers' },
  { label: 'Agile QA Engineers' },
  { label: 'Agile Team Leads' },
  { label: 'AI Designers' },
  { label: 'AI Engineers' },
  { label: 'Airbnb' },
  { label: 'Ajax' },
  { label: 'Alexa' },
  { label: 'Algorithm Developers' },
  { label: 'Altcoin' },
  { label: 'Amazon API' },
  { label: 'Amazon EC2' },
  { label: 'Amazon S3' },
  { label: 'Android' },
  { label: 'Android Designers' },
  { label: 'Android NDK' },
  { label: 'Android SDK' },
  { label: 'Angular' },
  { label: 'AngularJS' },
  { label: 'AngularJS Experts' },
  { label: 'Animation' },
  { label: 'Ansible' },
  { label: 'Apache' },
  { label: 'Apache Click' },
  { label: 'Apache Cordova' },
  { label: 'Apache OFBiz' },
  { label: 'Apache Shale' },
  { label: 'Apache Sling' },
  { label: 'Apache Struts 2' },
  { label: 'Apache Tapestry' },
  { label: 'Apache Tomcat' },
  { label: 'Apache Wicket' },
  { label: 'Apex' },
  { label: 'API' },
  { label: 'App Store' },
  { label: 'Appcelerator' },  
  { label: 'AppExchange' },
  { label: 'AppFuse' },
  { label: 'Apple' },
  { label: 'Apple Watch' },
  { label: 'Apple Watch Designers' },
  { label: 'ArcGIS' },
  { label: 'Arch Linux' },
  { label: 'ARCore' },
  { label: 'Arduino' },
  { label: 'ARKit' },  
  { label: 'ARM' },
  { label: 'Art Direction Experts' },
  { label: 'Artificial Intelligence Product Managers' },
  { label: 'Asana' },
  { label: 'Asana Experts' },
  { label: 'ASP.NET' },
  { label: 'ASP.NET Dynamic Data' },
  { label: 'ASP.NET MVC' },
  { label: 'Assembly' },
  { label: 'Asterisk' },
  { label: 'Atlassian Experts' },
  { label: 'Augmented Reality Designers' },
  { label: 'Augmented Reality Developers' },
  { label: 'Aurelia' },
  { label: 'Authorize.Net' },
  { label: 'Automation Developers' },
  { label: 'Automotive Industry Product Managers' },
  { label: 'AWK' },
  { label: 'AWS' },
  { label: 'AWS Cognito' },
  { label: 'AWS Lambda' },
  { label: 'Axure Experts' },
  { label: 'Azure' },
  { label: 'Babel' },
  { label: 'Backbone.js' },
  { label: 'Back-End Developers' },
  { label: 'bada' },
  { label: 'Banshee' },
  { label: 'Basecamp' },
  { label: 'Bash' },
  { label: 'BFC' },
  { label: 'Big Data Architects' },
  { label: 'BigCommerce' },
  { label: 'Bing Maps' },
  { label: 'Biotechnology Product Managers' },
  { label: 'Bitcoin' },
  { label: 'Bitcoin Cash' },
  { label: 'BlackBerry' },
  { label: 'Blender' },
  { label: 'Blockchain' },
  { label: 'Blockchain Consultants' },
  { label: 'Blockchain Designers' },
  { label: 'Blockchain Developers' },
  { label: 'Blog Designers' },
  { label: 'BlueBream' },
  { label: 'Bluetooth' },  
  { label: 'Boost' },
  { label: 'Bootstrap' },
  { label: 'Bottle' },
  { label: 'Bower Experts' },
  { label: 'Box' },
  { label: 'Brand Designers' },
  { label: 'Branding Experts' },
  { label: 'Brew' },
  { label: 'Brutos Framework' },
  { label: 'BuddyPress' },
  { label: 'Budgeting Consultants' },
  { label: 'Business Analysts' },
  { label: 'Business Intelligence' },
  { label: 'Business Intelligence Consultants' },
  { label: 'Business Model Consultants' },
  { label: 'Business Plan Consultants' },
  { label: 'Business Strategy Product Managers' },
  { label: 'C' },
  { label: 'C#' },
  { label: 'C++' },
  { label: 'CakePHP' },
  { label: 'Camping' },
  { label: 'Carma' },
  { label: 'CartoDB' },
  { label: 'Cash Flow Consultant' },
  { label: 'Cassandra' },
  { label: 'Catalyst' },
  { label: 'Caveman2' },
  { label: 'CFWheels' },
  { label: 'Chatbot' },
  { label: 'Chatbot Designers' },
  { label: 'CherryPy' },
  { label: 'Chrome Extension Developers' },
  { label: 'CI/CD Engineers' },
  { label: 'Cisco Engineers' },
  { label: 'Clarion' },
  { label: 'Clojure' },
  { label: 'Cloud Engineers' },
  { label: 'Cloud Product Managers' },
  { label: 'Clover' },
  { label: 'CMS' },
  { label: 'COBOL' },
  { label: 'Cocoa' },
  { label: 'CodeIgniter' },
  { label: 'CoffeeScript' },
  { label: 'Cognos' },
  { label: 'ColdBox Platform' },
  { label: 'ColdSpring' },
  { label: 'Computer Vision' },
  { label: ' Conceptual Designers' }, 
  { label: 'Contract' },
  { label: 'Conversational UI Designers' },
  { label: 'Core Java' },
  { label: 'Corona SDK' },
  { label: 'Corporate Controllers' },
  { label: 'Corporate Finance Consultants' },
  { label: 'Corporate Identity Designers' },
  { label: 'Couchbase' },
  { label: 'CouchDB' },
  { label: 'CppCMS' },
  { label: 'Creative Directors' },
  { label: 'CRM' },
  { label: 'Cross-Platform' },
  { label: 'Cryptocurrency' },
  { label: 'Crystal Reports' },
  { label: 'CSLA' },
  { label: 'CSS' },
  { label: 'CTOs' },
  { label: 'CubicWeb' },
  { label: 'CUDA' },
  { label: 'Cython' },
  { label: 'D' },
  { label: 'D3.js' },
  { label: 'Dagger' },
  { label: 'Dancer' },
  { label: 'ÐApp' },
  { label: 'Dart' },
  { label: 'Dashboard Designers' },
  { label: 'Data Analysis Consultants' },
  { label: 'Data Analysts' },
  { label: 'Data Engineers' },
  { label: 'Data Modeling Experts' },
  { label: 'Data Scientists' },
  { label: 'Data Visualization Designers' },
  { label: 'Data Warehouse' },
  { label: 'Database Developers' },
  { label: 'DB2' },
  { label: 'Debian' },
  { label: 'Dedicated .NET Developers' },
  { label: 'Dedicated Android Developers' },
  { label: 'Dedicated Developers' },
  { label: 'Dedicated Front-End Developers' },
  { label: 'Dedicated iPhone Developers' },
  { label: 'Dedicated Java Developers' },
  { label: 'Dedicated Magento Developers' },
  { label: 'Dedicated Mobile App Developers' },
  { label: 'Dedicated PHP Developers' },
  { label: 'Dedicated Web Developers' },
  { label: 'Dedicated WordPress Developers' },
  { label: 'Deep Learning Experts' },
  { label: 'Demandware' },
  { label: 'Design Directors' },
  { label: 'Design Leads' },
  { label: 'DevExpress' },
  { label: 'DevOps Engineers' },
  { label: 'Digital' },
  { label: 'Digital Designers' },
  { label: 'Digital Marketing Product Managers' },
  { label: 'Digital Product Designers' },
  { label: 'Directors of Product' },  
  { label: 'DirectX' },
  { label: 'Django' },
  { label: 'Docker' },
  { label: 'Dojo' },
  { label: 'DotNetNuke' },
  { label: 'Dropbox' },
  { label: 'Drupal' },
  { label: 'Due Diligence Consultants' },
  { label: 'DynamoDB' },
  { label: 'eBay API' },
  { label: 'Eclipse' },
  { label: 'Eclipse RAP' },
  { label: 'ECMAScript 6' },
  { label: 'eCommerce' },
  { label: 'E-Commerce Website Designers' },
  { label: 'EDI' },
  { label: 'Edtech Designers' },
  { label: 'Education Product Managers' },
  { label: 'Elasticsearch' },
  { label: 'eLearning Designers' },
  { label: 'Electron' },
  { label: 'Elgg' },
  { label: 'Elixir' },
  { label: 'Elm' },
  { label: 'Embedded Software Engineers' },
  { label: 'Ember.js' },
  { label: 'Energy Product Managers' },
  { label: ' Energy Sector Experts' }, 
  { label: 'Enterprise' },
  { label: 'Enterprise Coaches' },
  { label: 'Equity Research Analysts' },
  { label: 'Erlang' },
  { label: 'ERP' },
  { label: 'Ethereum' },
  { label: 'Ethereum Smart Contract' },
  { label: 'ETL' },
  { label: 'Evernote' },
  { label: 'Excel' },
  { label: 'Excel Experts' },
  { label: 'Expedia' },
  { label: 'Experience Design' },
  { label: 'Express.js' },
  { label: 'ExpressionEngine' },
  { label: 'Ext JS' },
  { label: 'Extended Reality Developers' },
  { label: 'F#' },
  { label: 'Facebook Ads' },
  { label: 'Facebook API' },
  { label: 'Family Office Consultants' },
  { label: 'Fashion Product Managers' },
  { label: 'Fastlane' },
  { label: 'Fat-Free' },
  { label: 'Figma Designers' },
  { label: 'Finance Experts' },
  { label: 'Finance Product Managers' },
  { label: 'Financial Analysts' },
  { label: 'Financial Consultants' },
  { label: 'Financial Forecasting' },
  { label: 'Financial Modeling Consultants' },
  { label: 'Financial Software Developers' },
  { label: 'Fintech Consultants' },
  { label: 'Fintech Designers' },
  { label: 'Firebase' },
  { label: 'Firefox' },
  { label: 'Fitbit' },
  { label: 'Flash' },
  { label: 'Flask' },
  { label: 'Flex' },
  { label: 'Flinto Designers' },
  { label: 'Flutter' },
  { label: 'Flux' },
  { label: 'Food Tech Designers' },
  { label: 'Force.com' },
  { label: 'FormEngine' },
  { label: 'Fortran' },
  { label: 'FoxPro' },
  { label: 'FP&A Managers' },
  { label: 'FPGA' },
  { label: 'Fractional CFOs' },
  { label: 'Framer.js Designers' },
  { label: 'FreeBSD' },
  { label: 'Freelance' },
  { label: 'Freelance Designers' },
  { label: 'Freelance Developers' },
  { label: 'Freelance Programmers' },
  { label: 'Friendster' },
  { label: 'Front-End Developers' },
  { label: 'FuelPHP' },
  { label: 'Full-Stack Developers' },
  { label: 'Fundraising Consultants' },
  { label: 'Fusebox' },
  { label: 'Game Developers' },
  { label: 'Gentoo' },
  { label: 'GIMP' },
  { label: 'GIS' },
  { label: 'Git' },
  { label: 'GitFlow' },
  { label: 'GitHub' },
  { label: 'Global Product Managers' },
  { label: 'Gmail' },
  { label: 'Go Engineers' },
  { label: 'Google AdWords' },
  { label: 'Google Analytics' },
  { label: 'Google API' },
  { label: 'Google App Engine' },
  { label: 'Google Checkout' },
  { label: 'Google Docs' },
  { label: 'Google Drive' },
  { label: 'Google Glass' },
  { label: 'Google Latitude' },
  { label: 'Google Maps' },
  { label: 'Google Play' },
  { label: 'Google Sites' },
  { label: 'Google Software Engineers' },
  { label: 'Google Storage' },
  { label: 'Google Web Toolkit' },
  { label: 'Gradle' },
  { label: 'Grails' },
  { label: 'Graphic Designers' },
  { label: 'GraphQL' },
  { label: 'Grok' },
  { label: 'Groovy' },
  { label: 'Groupon' },
  { label: 'Growth Marketing Product Managers' },
  { label: 'Growth Strategy Consultants' },
  { label: 'Grunt Experts' },
  { label: 'GUI Designers' },
  { label: 'Gulp Experts' },
  { label: 'Hadoop' },
  { label: 'HAML' },
  { label: 'Hamlets' },
  { label: 'Happstack' },
  { label: 'Haskell' },
  { label: 'Haxe' },
  { label: 'Hazaar MVC' },
  { label: 'Heads of Product' },
  { label: 'Healthcare Product Managers' },
  { label: 'HealthTech Designer' },
  { label: 'Heroku' },
  { label: 'HFT' },
  { label: 'hi5' },
  { label: 'Hibernate' },
  { label: 'HTC Vive' },
  { label: 'HTML5' },
  { label: 'Human-Centered' },
  { label: 'Hybrid App Developers' },
  { label: 'Hybris' },
  { label: 'Hyperledger' },
  { label: 'iAd' },
  { label: 'iBeacon' },
  { label: 'IBM Rational' },
  { label: 'iCloud' },
  { label: 'Icon Designers' },
  { label: 'Illustrators' },
  { label: 'Immersive Designers' },
  { label: 'Informatica' },
  { label: 'Information Architecture Experts' },
  { label: 'Infusionsoft' },
  { label: 'Instagram API' },
  { label: 'Insurance Product Managers' },
  { label: 'Interactive Designers' },
  { label: 'Interactive Prototyping Experts' },
  { label: 'Interim CFOs' },
  { label: 'Interim CPOs' },
  { label: 'Interim Finance Directors' },
  { label: 'Interim Finance Managers' },
  { label: 'Interim Financial Controllers' },
  { label: 'Investment Bankers' },
  { label: 'InVision Experts' },
  { label: 'InVision Studio Designers' },
  { label: 'Ionic' },
  { label: 'iOS' },
  { label: 'iOS Designers' },
  { label: 'IoT Designers' },
  { label: 'IoT Developers' },
  {label: 'IP and Patent Experts on Demand'},
  { label: 'iPad' },
  { label: 'iPad Designers' },
  { label: 'iPhone App Development Freelancer' },
  { label: 'iPhone Designers' },
  { label: 'IT' },
  { label: 'ItsNat' },
  { label: 'Jade' },
  { label: 'Jasmine' },
  { label: 'Java' },
  { label: 'Java EE' },
  { label: 'JavaScript' },
  { label: 'JavaScript Consultants' },
  { label: 'JavaServer Faces' },
  { label: 'JBoss' },
  { label: 'JBoss Seam' },
  { label: 'Jenkins' },
  { label: 'JIRA' },
  { label: 'JIRA Administrators' },
  { label: 'JIRA Experts' },
  { label: 'JMS' },
  { label: 'jQuery' },
  { label: 'JSON Expert' },
  { label: 'JSP' },
  { label: 'Jspx-bay' },
  { label: 'Julia' },
  { label: 'JUnit' },
  { label: 'JVx' },
  { label: 'Kafka' },
  { label: 'Kajona' },
  { label: 'Kanban' },
  { label: 'Keynote Designers' },
  { label: 'Kindle' },
  { label: 'Knockout.js' },
  { label: 'Kohana' },
  { label: 'Kotlin' },
  { label: 'Kubernetes Experts' },
  { label: 'LabVIEW' },
  { label: 'LAMP' },
  { label: 'Landing Page Designers' },
  { label: 'Laravel' },
  { label: 'Layar' },
  { label: 'Lean' },
  { label: 'Lean Product Managers' },
  { label: 'Leaner Style Sheets (LESS)' },
  { label: 'Leap Motion' },
  { label: 'Learning Management System Administrators' },
  { label: 'Lift' },
  { label: 'Lightning' },
  { label: 'LinkedIn API' },
  { label: 'LINQ' },
  { label: 'Linux' },
  { label: 'Linux CentOS' },
  { label: 'Lisp' },
  { label: 'Lithium' },
  { label: 'LMS' },
  { label: 'Logo Designers' },
  { label: 'Lotus Notes' },
  { label: 'Lua' },
  { label: 'Mac OS' },
  { label: 'Mach-II' },
  { label: 'Machine Learning Engineers' },
  { label: 'Magento' },
  { label: 'MailChimp' },
  { label: 'Management Consultants on Demand' },
  { label: 'Manual Testers' },
  { label: 'Manufacturing Product Managers' },
  { label: 'MapKit' },
  { label: 'MapQuest' },
  { label: 'MariaDB' },
  { label: 'Markdown' },
  { label: 'Market Research Analysts' },
  { label: 'Market Sizing' },
  { label: 'Market Strategy and Research Product Managers' },
  { label: 'Marketplace Designers' },
  { label: 'Marvel App Experts' },
  { label: 'Mason' },
  { label: 'MATLAB' },
  { label: 'Maven Experts' },
  { label: 'Maya' },
  { label: 'Maypole' },
  { label: 'MEAN Stack Developers' },
  { label: 'Mega' },
  { label: 'Merb' },
  { label: 'Mercurial Experts' },
  { label: 'Mergers and Acquisitions Consultants' },
  { label: 'Meteor' },
  { label: 'Microsoft' },
  { label: 'Microsoft Access' },
  { label: 'Microsoft Dynamics CRM' },
  { label: 'Microsoft Kinect' },
  { label: 'Mixed Reality Developers' },
  { label: 'Mobile' },
  { label: 'Mobile App Designers' },
  { label: 'Mobile App Developers' },
  { label: 'Mobile Developers' },
  { label: 'Mobile Product Managers' },
  { label: 'Mobile UI Designers' },
  { label: 'Mobile UX Designers' },
  { label: 'Mocha' },
  { label: 'Mockup Designers' },
  { label: 'Model-Glue' },
  { label: 'MODx' },
  { label: 'Mojolicious' },
  { label: 'Moneybookers' },
  { label: 'MongoDB' },
  { label: 'Mono' },
  { label: 'MonoRail' },
  { label: 'Moodle' },
  { label: 'MS SQL Developers' },
  { label: 'Multithreading Experts' },
  { label: 'Music Product Managers' },
  { label: 'MVP Designers' },
  { label: 'MySQL' },
  { label: 'Nagare' },
  { label: 'Native App Designers' },
  { label: 'NativeScript' },
  { label: 'Neo4j' },
  { label: 'NetBeans' },
  { label: 'NetBSD' },
  { label: 'Nette Framework' },
  { label: 'Neural Network' },
  { label: 'Nexus' },
  { label: 'Nginx Experts' },
  { label: 'NLP' },
  { label: 'Node.js' },
  { label: 'nopCommerce' },
  { label: 'NoSQL' },
  { label: 'NumPy' },
  { label: 'Objective-C' },
  { label: 'Object-Oriented Programming Experts' },
  { label: 'OCaml' },
  { label: 'Octave' },
  { label: 'Oculus Rift' },
  { label: 'Offshore Developers' },
  { label: 'OmniGraffle Designers' },
  { label: 'OneDrive' },
  { label: 'Open Source' },
  { label: 'OpenBSD' },
  { label: 'OpenCart' },
  { label: 'OpenCV' },
  { label: 'OpenERP' },
  { label: 'OpenGL' },
  { label: 'OpenLaszlo' },
  { label: 'OpenRasta' },
  { label: 'OpenSocial' },
  { label: 'OpenStack' },
  { label: 'OpenUI5' },
  { label: 'OpenXava' },
  { label: 'Oracle' },
  { label: 'Oracle ADF' },
  { label: 'Oracle Forms' },
  { label: 'Oracle SQL' },
  { label: 'Origami Designers' },
  { label: 'osCommerce' },
  { label: 'Outsourced Developers' },
  { label: 'Outsourced Product Management Professionals' },
  { label: 'Padrino' },
  { label: 'Pandas' },
  { label: 'Parse' },
  { label: 'Part-Time CFOs' },
  { label: 'Pascal' },
  { label: 'PayPal' },
  { label: 'Pebble' },
  { label: 'Perl' },
  { label: 'Phalcon' },
  { label: 'Phoenix' },
  { label: 'PhoneGap' },
  { label: 'Photoshop Experts' },
  { label: 'PHP' },
  { label: 'phpBB' },
  { label: 'PHPfox' },
  { label: 'PHPixie' },
  { label: 'PHPUnit' },
  { label: 'Pitch Deck Consultants' },
  { label: 'PL/SQL' },
  { label: 'Play' },
  { label: 'Play Framework' },
  { label: 'Plone' },
  { label: 'PMI' },
  { label: 'PMO Specialists' },
  { label: 'PMP' },
  { label: 'Pocket' },
  { label: 'Poco' },
  { label: 'Portal Designers' },
  { label: 'POS' },
  { label: 'PostgreSQL' },
  { label: 'Post-Merger Integration' },
  { label: 'Power BI' },
  { label: 'PowerBuilder' },
  { label: 'PowerPoint Presentation Designers' },
  { label: 'PRADO' },
  { label: 'Presentation Designers' },
  { label: 'PrestaShop' },
  { label: 'Prezi' },
  { label: 'Pricing Consultants' },
  { label: 'Prince2 Experts' },
  { label: 'Principle Designers' },
  { label: 'Prism' },
  { label: 'Private Equity Consultants' },
  { label: 'Product Coaches' },
  { label: 'Product Consultants' },
  { label: 'Product Designers' },
  { label: 'Product Development Managers' },
  { label: 'Product Leaders' },
  { label: 'Product Management Consultants' },
  { label: 'Product Owners' },
  { label: 'Profitability Analysis' },
  { label: 'Program Managers' },
  { label: 'Project Management Consultants' },
  { label: 'Proto.io Designers' },
  { label: 'Prototype' },
  { label: 'Prototyping Experts' },
  { label: 'Protractor' },
  { label: 'Pug' },
  { label: 'Puppeteer' },
  { label: 'PureMVC' },
  { label: 'PyCharm Experts' },
  { label: 'Pyjs' },
  { label: 'Pylons' },
  { label: 'PyObjC' },
  { label: 'PyQt' },
  { label: 'Pyramid' },
  { label: 'Python' },
  { label: 'QA Analysts' },
  { label: 'QA Engineers' },
  { label: 'QA Leads' },
  { label: 'QA Managers' },
  { label: 'QA Specialists' },
  { label: 'QA Testers' },
  { label: 'QlikView' },
  { label: 'Qt' },
  { label: 'Quantitative' },
  { label: 'QuickBase' },
  { label: 'QuickBooks' },
  { label: 'R' },
  { label: 'R3 Corda' },
  { label: 'Rackspace' },
  { label: 'Ractive.js' },
  { label: 'Raspberry Pi' },
  { label: 'React Native' },
  { label: 'React.js' },
  { label: 'Real Estate' },
  { label: 'Realm' },
  { label: 'Redis' },
  { label: 'Redux' },
  { label: 'Regex' },
  { label: 'Release Engineers' },
  { label: 'Remote' },
  { label: 'Remote Developers' },
  { label: 'Requirements Analysts' },
  { label: 'Responsive Web Designers' },
  { label: 'Responsive Web Developers' },
  { label: 'REST API' },
  { label: 'Retrofit' },
  { label: 'Riak' },
  { label: 'RIFE' },
  { label: 'Robotics Engineers' },
  { label: 'Robotics Product Managers' },
  { label: 'RSS' },
  { label: 'Ruby' },
  { label: 'Ruby on Rails' },
  { label: 'Rust' },
  { label: 'RxJava' },
  { label: 'RxJS' },
  { label: 'RxSwift' },
  { label: 'SaaS' },
  { label: 'SaaS Product Managers' },
  { label: 'Saetta Web Server' },
  { label: 'SAFe' },
  { label: 'Sails.js' },
  { label: 'Salesforce' },
  { label: 'Salesforce Consultants' },
  { label: 'Salesforce Lightning' },
  { label: 'Salesforce1' },
  { label: 'SAML' },
  { label: 'SAS' },
  { label: 'Sass' },
  { label: 'SCADA' },
  { label: 'Scala' },
  { label: 'Scalatra' },
  { label: 'Scientific Software Developers' },
  { label: 'SCM Engineers' },
  { label: 'Scrum' },
  { label: 'Scrum Coaches' },
  { label: 'Scrum Masters' },
  { label: 'Scrum Product Owner Contractors' },
  { label: 'SDETs' },
  { label: 'Search Fund Experts' },
  { label: 'Selenium' },
  { label: 'Sencha Touch' },
  { label: 'SharePoint' },
  { label: 'Shopify' },
  { label: 'Shopify Web Designers' },
  { label: 'Silex' },
  { label: 'Silverlight' },
  { label: 'SilverStripe' },
  { label: 'Sinatra' },
  { label: 'Sitefinity' },
  { label: 'Sketch Experts' },
  { label: 'Slack' },
  { label: 'Small Business Web Designers' },
  { label: 'Smart Contract' },
  { label: 'Smarty' },
  { label: 'SMS' },
  { label: 'Snap' },
  { label: 'SOA' },
  { label: 'SOAP' },
  { label: 'Socket.IO' },
  { label: 'Software' },
  { label: 'Software Architects' },
  { label: 'Software Consultants' },
  { label: 'Software Delivery' },
  { label: 'Software Developers' },
  { label: 'Software Development' },
  { label: 'Software Engineers' },
  { label: 'Solaris' },
  { label: 'Solidity' },
  { label: 'SOLIDWORKS' },
  { label: 'Spark' },
  { label: 'Splunk' },
  { label: 'Sports Product Managers' },
  { label: 'Spotify' },
  { label: 'Spree Commerce' },
  { label: 'Spring' },
  { label: 'Spring Boot' },
  { label: 'SQL' },
  { label: 'SQL CLR' },
  { label: 'SQL Server' },
  { label: 'SQLite' },
  { label: 'Squarespace' },
  { label: 'SSIS' },
  { label: 'Startup' },
  { label: 'Start-up Controller' },
  { label: 'Startup Design Experts' },
  { label: 'Startup Funding Consultants' },
  { label: 'Stripe' },
  { label: 'Stripes' },
  { label: 'Struts' },
  { label: 'Subversion (SVN)' },
  { label: 'SugarCRM' },
  { label: 'SUN' },
  { label: 'SVG' },
  { label: 'Swift' },
  { label: 'Sybase' },
  { label: 'Symbian' },
  { label: 'Symfony' },
  { label: 'Sys Admins' },
  { label: 'System Security Developers' },
  { label: 'Tableau' },
  { label: 'TACTIC' },
  { label: 'Takes' },
  { label: 'Technical' },
  { label: 'Technical Business Analysts' },
  { label: 'Technical Program Managers' },
  { label: 'Technical Writers' },
  { label: 'Telerik' },
  { label: 'TensorFlow' },
  { label: 'Teradata' },
  { label: 'Test Automation Architects' },
  { label: 'Test Automation Engineers' },
  { label: 'Test Engineers' },
  { label: 'ThinWire' },
  { label: 'Three.js' },
  { label: 'TIBCO' },
  { label: 'Titanium' },
  { label: 'Tizen' },
  { label: 'Tntnet' },
  { label: 'TokBox' },
  { label: 'Tornado' },
  { label: 'Travel Product Managers' },
  { label: 'Treasury Manager' },
  { label: 'Trello' },
  { label: 'T-SQL' },
  { label: 'Tumblr' },
  { label: 'TurboGears' },
  { label: 'TV UI Design' },
  { label: 'Twilio' },
  { label: 'Twitter' },
  { label: 'Twitter Bootstrap' },
  { label: 'TypeScript' },
  { label: 'TYPO3' },
  { label: 'TYPO3 Flow' },
  { label: 'Typography Specialists' },
  { label: 'Uber' },
  { label: 'Ubuntu' },
  { label: 'UI' },
  { label: 'UI Designers' },
  { label: 'UI UX Designers' },
  { label: 'UI, UX, and Front-End Developers' },
  { label: 'UI/UX' },
  { label: 'UIKit' },
  { label: 'Umbraco' },
  { label: 'UML' },
  { label: 'Underscore.js' },
  { label: 'Unified.js' },
  { label: 'Unit Testing Experts' },
  { label: 'Unity or Unity3D' },
  { label: 'Unix' },
  { label: 'Unreal Engine' },
  { label: 'Usability Testing Experts' },
  { label: 'User Experience Researchers' },
  { label: 'User Flow Designers' },
  { label: 'UX' },
  { label: 'UX Consultants' },
  { label: 'UX Designers' },
  { label: 'UX Prototypers' },
  { label: 'UX Researchers' },
  { label: 'UXPin Designers' },
  { label: 'Vaadin' },
  { label: 'Valuation Specialists' },
  { label: 'VB.NET' },
  { label: 'VBA' },
  { label: 'VBScript' },
  { label: 'vBulletin' },
  { label: 'Velocity' },
  { label: 'Venture Capital Consultants' },
  { label: 'Vibe.d' },
  { label: 'Vimeo' },
  { label: 'Vine' },
  { label: 'Virtual' },
  { label: 'Virtual Assistant' },
  { label: 'Virtual CFOs' },
  { label: 'Virtual Reality Designers' },
  { label: 'Virtual Reality Developers' },
  { label: 'Visio' },
  { label: 'Visual Basic' },
  { label: 'Visual Designers' },
  { label: 'Visual Identity Designers' },
  { label: 'Visual Studio' },
  { label: 'Visualforce' },
  { label: 'VMware Engineers' },
  { label: 'VoIP Engineers' },
  { label: 'Volusion' },
  { label: 'VRaptor' },
  { label: 'VSS' },
  { label: 'vTiger' },
  { label: 'Vue.js' },
  { label: 'Vuforia' },
  { label: 'Waterfall' },
  { label: 'Wavemaker' },
  { label: 'Web' },
  { label: 'Web Application Designers' },
  { label: 'Web Designers' },
  { label: 'Web Developers' },
  { label: 'Web UI Designers' },
  { label: 'web2py' },
  { label: 'WebKit' },
  { label: 'WebObjects' },
  { label: 'webOS' },
  { label: 'WebRTC' },
  { label: 'WebSphere' },
  { label: 'WebStorm Experts' },
  { label: 'Webware' },
  { label: 'WebWork' },
  { label: 'Weebly' },
  { label: 'Windows' },
  { label: 'Windows App' },
  { label: 'Windows Phone' },
  { label: 'WinForms' },
  { label: 'Wireframing Experts' },
  { label: 'WooCommerce' },
  { label: 'WordPress' },
  { label: 'WordPress Designers' },
  { label: 'WordPress Plugin' },
  { label: 'Wowza' },
  { label: 'WPF' },
  { label: 'Wt' },
  { label: 'Xamarin' },
  { label: 'XAML' },
  { label: 'Xbox' },
  { label: 'Xcode' },
  { label: 'Xero' },
  { label: 'XML' },
  { label: 'XNA' },
  { label: 'XP' },
  { label: 'XPath' },
  { label: 'XSLT' },
  { label: 'Yahoo! Software Engineers' },
  { label: 'Yahoo! Store' },
  { label: 'YAML' },
  { label: 'Yelp API' },
  { label: 'Yesod' },
  { label: 'Yii' },
  { label: 'Yii 2' },
  { label: 'YouTube API' },
  { label: 'Ze Framework' },
  { label: 'Zen Cart' },
  { label: 'Zend' },
  { label: 'Zendesk' },
  { label: 'Zeplin Designers' },
  { label: 'ZK' },
  { label: 'Zope 2' },
  { label: 'ztemplates' },
  { label: 'ZURB Foundation' },

].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minWidth: 290,
  },
  input: {
    display: 'flex',
    // padding: 0,
    height: 'auto',
    // height:'2.6em',
  },

  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },

  placeholder: {
    position: 'absolute',
    // left: 2,
    // bottom: 6,
    // fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.element.isRequired,
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

const components = {
  Control,
  Menu,
  MultiValue,
  Placeholder,
};

export default function SkillSet(props) {
  const classes = useStyles();
  const [multi, setMulti] = React.useState(null);

  function handleChangeMulti(value) {
    props.onChange(value);
    setMulti(value);
  }

  return (
    <div className={classes.root} id="skillset">
      <NoSsr>

        <Select
          classes={classes}
          inputId="react-select-multiple"
          TextFieldProps={{

          }}
          className={(props.isFreelancerForm) ? "skillset-style required" : "skillset-style"}
          placeholder={(props.isFreelancerForm) ? "Skill Sets" : "Skill Sets Required"}
          options={suggestions}
          components={components}
          value={multi}
          onChange={handleChangeMulti}
          isMulti
          variant="filled"
        />
      </NoSsr>
    </div>
  );
}
