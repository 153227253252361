import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import Fade from "react-reveal/Fade";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import posed from 'react-pose';
import uuid from "uuid";

import ContactDetail from "./ContactDetails";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import "./contact.scss";
import SideMenu from '../SideMenu/SideMenu';

const Container = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 1 }
});

const images = [
  {
    url: "./image/NCEPTIO-Dallas-Location.jpg",
    title: "DALLAS - CRESCENT COURT",
    width: "33%",
    address: "100 CRESCENT COURT SUITE 700 DALLAS, TX 75201"
    
  },
  {
    url: "./image/NCEPTIO-Houston-Location.jpg",
    title: "HOUSTON - KIRBY GROVE",
    width: "33%",
    address: "2925 Richmond Ave., Suite 1200 Houston, TX 77098"
  },
  {
    url: "./image/NCEPTIO-Santa-Monica_Location.jpg",
    title: "SANTA MONICA - SILICON BEACH",
    width: "33%",
    address: "By appointment only"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    flexGrow: 1,
  },

  image: {
    position: "relative",
    height: "100%",
    width: "100%",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "10px",
    border: "none",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: "100%"
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15
      },
      "& $imageMarked": {
        opacity: 0
      },
      "& $imageTitle": {
        border: "4px solid currentColor"
      }
    }
  },

  paper: {
    padding: theme.spacing(2),
    margin: "auto"
    // maxWidth: 500,
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fafaef",
    "&:hover" :{
      "& $imageTitle" : {
          backgroundColor: "#00000075 !important"
      }   
  }
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "block",
    backgroundSize: "100% 100%",
    width: "100%",
    height: "100%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'black !important',
    opacity: 0.4,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: "#fafaef",
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  }
}));

export default function Contact(props) {
  const classes = useStyles();
  const { pathname } = props.location;
  const [imageSrc, setImage] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [menuOpen, setMenuOpen] = useState(false); 
  const [toggle, setToggle] = useState(false);
  const [imageText, setText] = useState("");
  const [imageAddress, setAddress] = useState("");
  const [contactFooter, setFooter] = useState("");
  const landscapeFlag = window.matchMedia("(orientation: landscape)").matches; //0=false, >0 true
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    subject: '',
    message : ''
  });

  function changeImage(index) {
    setImage(images[index].url);
    setToggle(!toggle);
    setText(images[index].title);
    setAddress(images[index].address)
  }

  function handleClose() {
    setToggle(!toggle);
  }

  function setContactForm(key, value) {
    setValues({ ...values, [key]: value });
    console.log(values)
  }

  function toggleMenu() {
    setMenuOpen(!menuOpen)   
  }

  return (
    <Container>
      <SideMenu isOpen={menuOpen} toggleMenu={toggleMenu} pathname={pathname} history={props.history}/>
      <div className="container">
        <Header title1="CONTACT" title2="US" page={pathname} toggleMenu={toggleMenu}/>

        <div className={!toggle ? "contact-content" : "contact-content-opacity"}>
          {/* <div className="contact-weare contact contact-desc intro-parent-section"> */}
          <div className="contact-weare contact contact-desc">
            <div className="intro-section">
            <Fade delay={2000}>
              <h1 className="intro-title">
                <a href="#">Hello :-)</a>
              </h1>
           </Fade>
            <Fade delay={3000}>
              <div className="we-are-text">
              <h2 className="sub-title">
                please <b>select</b> a <b>location</b>
              </h2>
              </div>
            </Fade>
            </div>

            <Fade delay={4000}>
                <div className="contacts-grid-of-img">
              <div className={classes.root}>
                <Grid container spacing={1}>
                  {images.map((image, index) => (
                    <Grid key={uuid.v1()} item xs={landscapeFlag ? 4 : 12} md={landscapeFlag ? 4 : 12} lg={4} onClick={() => changeImage(index)} className="contact-img-grid">
                      <ButtonBase
                        disableRipple
                        className={classes.image}
                        style={{
                          backgroundImage: `url(${image.url})`
                        }}
                      >
                        <Paper className={classes.imageBackdrop}></Paper>
                        <Paper className={classes.imageButton}>
                          <Typography
                            component="span"
                            variant="subtitle1"
                            color="inherit"
                            className={classes.imageTitle}
                          >
                            {image.title}
                            <span className={classes.imageMarked} />
                          </Typography>
                        </Paper>
                        {/* </div> */}
                      </ButtonBase>
                    </Grid>
                  ))}
                </Grid>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        <ContactDetail
          subject={subject}
          setSubject={setSubject}
          email={email}
          setEmail={setEmail}
          contactFormValues={values}
          setContactFormValues={setContactForm}
          image={imageSrc}
          toggle={toggle}
          imageText={imageText}
          onClose={handleClose}
          contactFooter={contactFooter}
          imageAddress={imageAddress}
        />
        <Footer />
      </div>
    </Container>
  );
}
