import React, { useState, useEffect, useRef } from "react";
import "react-id-swiper/lib/styles/scss/swiper.scss";
import "react-animated-slider/build/vertical.css";
import posed from "react-pose";
import "animate.css/animate.min.css";
import ScrollSnap from "scroll-snap";
import uuid from "uuid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Div100vh from "react-div-100vh";
import { BrowserView, MobileView } from "react-device-detect";
// import {
//   useTransition,
//   useSpring,
//   useChain,
//   config,
//   animated
// } from "react-spring";
import WhatService from './section-what'
import WhyService from './section-why'
import WhoService from './section-who'
import CoreService from './section-core'
import { menuData } from "./data";
import SideMenu from "../SideMenu/SideMenu";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import "./services.scss";

const snapConfig = {
  scrollSnapDestination: "0% 100vh",
  scrollTimeout: 300,
  scrollTime: 200
};

const Container = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 1 }
});

const useStyles = makeStyles(theme => ({
  root: {},
  overflowHidden: {
    overflow: "hidden"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center"
  }
}));

export default function Services(props) {
  let container = React.createRef();
  let progressInterval;
  const [activeIndex, setActiveIndex] = useState(0);
  const [svgChanged, setsvgChanged] = useState(false);
  let [progress, setProgress] = useState(0);
  let [stopProgress, setStopProgress] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  // const classes = useStyles();
  // const overflowHidden = useStyles();
  const { pathname } = props.location;

  if (activeIndex === 2 && stopProgress) {
    progressInterval = setInterval(progressBarInterval, 1000);
  }

  function progressBarInterval() {
    progress = progress + 20;
    setProgress(progress);
    if (progress > 100) {
      setStopProgress(false);
      killProgressBarInterval(progressInterval);
    }
  }

  function killProgressBarInterval() {
    clearInterval(progressInterval);
  }

  // const [open, set] = useState(false);

  // const springRef = useRef();
  // const { size, opacity, ...rest } = useSpring({
  //   ref: springRef,
  //   config: config.stiff,
  //   from: { background: "#00000000" },
  //   to: { background: open ? "fafaef" : "#00000000" }
  // });

  // const transRef = useRef();
  // const transitions = useTransition(open ? data : [], item => item.name, {
  //   ref: transRef,
  //   unique: true,
  //   trail: 400 / data.length,
  //   from: { opacity: 0, transform: "scale(0)" },
  //   enter: { opacity: 1, transform: "scale(1)" },
  //   leave: { opacity: 0, transform: "scale(0)" }
  // });

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  // useChain(open ? [springRef, transRef] : [transRef, springRef], [
  //   0,
  //   open ? 0.1 : 0.6
  // ]);

  useEffect(() => {
    bindScrollSnap();
  });

  function bindScrollSnap() {
    const callback = () => {
      if (container.current) {
        const activeIndex = Math.round(
          container.current.scrollTop / container.current.offsetHeight
        );
        if (activeIndex === 1) {
          setsvgChanged({ svgChanged: true });
        }

        setActiveIndex(activeIndex);
      }
    };
    const element = container.current;
    const snapObject = new ScrollSnap(element, snapConfig);
    snapObject.bind(callback);
  }

  function pagination(index) {
    setActiveIndex(index);
    if (index === 1) {
      setsvgChanged({ svgChanged: true });
    }
  }

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  
  return (
    <Container>
      <SideMenu isOpen={menuOpen} toggleMenu={toggleMenu} pathname={pathname} history={props.history} />
      <div className="container" ref={container}>
        <Header
          title1="OUR"
          title2="SERVICES"
          page={pathname}
          toggleMenu={toggleMenu}
        />
        <MobileView>
          <Header title1="SERVICES" page={pathname} toggleMenu={toggleMenu} />
        </MobileView>
        <div
          className="aboutus-content services-new-content scroll"
          id="scroll"
                >
          <Div100vh className="paginateWrapper">
            <ul>
              {menuData &&
                menuData.map((menuItem, index) => {
                  return (
                    <li
                      key={uuid.v1()}
                      className={index === activeIndex ? "active" : ""}
                      onClick={() => pagination(index)}
                    >
                      <a href={`#${menuItem.path}`} />
                    </li>
                  );
                })}
            </ul>
          </Div100vh>
          <WhatService />
          <WhyService svgChanged={svgChanged} />
          <WhoService progress={progress}/>
          <CoreService />
        </div>
        <Footer />
      </div>
    </Container>
  );
}
