import React from "react";
import Fade from "react-reveal/Fade";
import Div100vh from "react-div-100vh";

export default function WhatService(props) {
    return (
        <Div100vh
            id="about"
            className="about-container-div about-tile scroll-item v-center-flex service-container-div intro-parent-section"
        >
            <Fade delay={2000}>
                <div className="intro-section">
                    <h1 className="intro-title">What</h1>
                </div>
            </Fade>
            <div className="we-are-text about__text-desc service__text-desc about-sub-text service-sub-text-justify-evenly service__text-desc-margin">
                <div className="about-sub-text">
                    <Fade delay={3000} duration={3000}>
                        <h2>
                            we <b>provide</b> top <b>freelance talent</b>
                        </h2>
                    </Fade>
                    <Fade delay={3000} duration={3000}>
                        <h2>
                            <b>to work</b> on <b>projects</b>
                        </h2>
                    </Fade>
                    <Fade delay={3000} duration={3000}>
                        <h2>
                            <b>as </b> a part of the <b>client team</b>
                        </h2>
                    </Fade>
                </div>

                <div className="service__text-img service-why__text-img">
                    <div className="service__text-img-1 service-img">
                        <div className="h-70">
                            <img
                                className="slide-right what-bg-img"
                                src="./image/client-team-grouped.svg"
                            />
                        </div>
                        <Fade delay={7000} duration={6000}>
                            <h6 className="font-mobile font-relative">CLIENT TEAM</h6>
                        </Fade>
                    </div>

                    <div className="service__text-img-vertical-line scale-in-ver-center" />
                    <div className="service__text-img-horizontal-line tracking-out-contract-bck" ref={props.calculatePositon}/>

                    <div className="service__text-img-2 service-img">
                        <div className="h-70">
                            <img
                                className="slide-left what-bg-img"
                                src="./image/nceptio-freelancer.svg"
                            />
                        </div>
                        <Fade delay={7000} duration={6000}>
                            <h6 className="font-mobile font-relative">
                                NCEPTIO FREELANCER
                            </h6>
                        </Fade>
                    </div>
                </div>
            </div>
        </Div100vh>
    )
}
