import React, { useState, useEffect, useRef } from "react";
import Div100vh from "react-div-100vh";
import Fade from "react-reveal/Fade";
import {
    useTransition,
    useSpring,
    useChain,
    config,
    animated
} from "react-spring";
import { slides } from "./data";

const App = () => {
    const [index, set] = useState(0);
    const transitions = useTransition(slides[index], item => item.id, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses
    });
    useEffect(
        () => void setInterval(() => set(state => (state + 1) % 5), 2000),
        []
    );
    return transitions.map(({ item, props, key }) => (
        <animated.div
            delay="4500"
            key={key}
            title="test"
            className="bg"
            style={{ ...props, backgroundImage: `url(${item.url})` }}
        >
            <div
                className="mobile-icon"
                style={{ ...props, backgroundImage: `url(${item.icon})` }}
            />
            <h4 className="font-mobile-who">{item.title}</h4>
        </animated.div>
    ));
};

export default function WhoService(props) {
    console.log(props.bottomThreshold)
    return (
        <Div100vh
            className="who scroll-item v-center-flex intro-parent-section min-height-fill"
            id="who"
        >
            {/* <Fade delay={2000} duration={3000}> */}
            <div className="intro-section">
                <h1 className="intro-title">Who</h1>
            </div>
            {/* </Fade> */}
            <div className="we-are-text about__text-desc service__text-desc about-sub-text service-sub-text-justify-evenly service-sub-text-justify-around">

                {/* <Fade delay={3000} duration={3000}> */}
                <div className="new-who-class about-sub-text">
                    <h2 id="Approach-text1">
                        our <b> hand-selected talent </b> includes:
                  </h2>
                </div>
                {/* </Fade> */}

                <div className="who-service-web">


                    <div className="service__text-img service-who-sec service-sub-text-justify-evenly">
                            <div
                                className="service-who-horizontal-line"
                                style={{ width: `${props.progress}%`}}
                            />
                        <div className="xyz">
                            <div className="h-70-new small-img-position">
                                <Fade bottom y={0} delay={1300}>
                                <div className="service-who-vertical-line" />
                                <img
                                    className="service-small-img"
                                    src="./image/mouse.svg"
                                />
                                </Fade>
                            </div>
                            <div className="who-service__text-img-1 div-relative">
                                <Fade delay={1300}>
                                <div className="h-70-new who-big-image">
                                    <img className="" src="./image/developer.svg" />
                                </div>
                                {/* <div> */}
                                <div className="text-absolute">
                                    {" "}
                                    <h6>DEVELOPERS</h6>
                                </div>
                                {/* </div> */}
                                </Fade>
                            </div>

                            {/* </div> */}
                            <div className="h-70-new small-img-position">
                                <Fade bottom delay={2600}>
                                <div className="service-who-vertical-line" />
                                <img
                                    className="service-small-img"
                                    src="./image/pencil.svg"
                                />
                                </Fade>
                            </div>

                            <div className="who-service__text-img-2 div-relative">
                                <Fade delay={2600}>
                                <div className="h-70-new who-big-image">
                                    <img className="" src="./image/designer.svg" />
                                </div>
                                <div className="text-absolute">
                                    {" "}
                                    <h6>DESIGNERS</h6>
                                </div>
                                </Fade>
                            </div>
                            <div className="h-70-new small-img-position">
                                <Fade bottom delay={3670}>
                                <div className="service-who-vertical-line" />
                                <img
                                    className="service-small-img"
                                    src="./image/folder.svg"
                                />
                                </Fade>
                            </div>

                            <div className="who-service__text-img-3 div-relative">
                                <Fade delay={3670}>
                                <div className="h-70-new who-big-image">
                                    <img className="" src="./image/project_manager.svg" />
                                </div>
                                <div className="text-absolute">
                                    {" "}
                                    <h6>PROJECT MANAGERS</h6>
                                </div>
                                </Fade>
                            </div>
                            <div className="h-70-new small-img-position">
                                <Fade bottom delay={4700}>
                                <div className="service-who-vertical-line" />
                                <img
                                    className="service-small-img"
                                    src="./image/graph.svg"
                                />
                                </Fade>
                            </div>

                            <div className="who-service__text-img-4 div-relative">
                                <Fade delay={4700}>
                                <div className="h-70-new who-big-image">
                                    <img className="" src="./image/Data_Scientist.svg" />
                                </div>
                                <div className="text-absolute">
                                    {" "}
                                    <h6>DATA SCIENTISTS</h6>
                                </div>
                                </Fade>
                            </div>
                            <div className="h-70-new small-img-position">
                                <Fade bottom delay={5600}>
                                <div className="service-who-vertical-line" />
                                <img
                                    className="service-small-img"
                                    src="./image/question_mark.svg"
                                />
                                </Fade>
                            </div>

                            <div className="who-service__text-img-4 div-relative">
                                <Fade delay={5600}>
                                <div className="h-70-new who-big-image">
                                    <img className="" src="./image/nceptio-other-experts-group.svg" />
                                </div>
                                <div className="text-absolute">
                                    {" "}
                                    <h6>OTHER EXPERTS</h6>
                                </div>
                                </Fade>
                            </div>

                           </div>
                    </div>
                </div>
                <div className="service-who-sec service-sub-text-justify-evenly who-service-mobile">
                    {/* <Fade delay={3000} duration={3000}> */}
                    {/* <h2 id="Approach-text1">
                  our <b> hand-selected talent </b> includes:
                </h2> */}
                    {/* </Fade> */}
                    {/* <Fade delay={6000}> */}
                    <App />
                    {/* </Fade> */}
                </div>
            </div>
        </Div100vh>
    )
}
