import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ChevronRightCircleOutlineIcon from "mdi-react/ChevronRightCircleOutlineIcon";
import ChatBubble from "mdi-react/ChatBubbleIcon";

import InstagramIcon from "mdi-react/InstagramIcon";
import TwitterIcon from "mdi-react/TwitterIcon";
// import LinkedinIcon from "mdi-react/LinkedinIcon";
// import FacebookIcon from "mdi-react/FacebookIcon";
import AccountPlusIcon from "mdi-react/AccountPlusIcon";
import EmailIcon from "mdi-react/EmailIcon";
import PhoneIcon from "mdi-react/PhoneIcon";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FacebookBoxIcon from "mdi-react/FacebookBoxIcon";
import LinkedinBoxIcon from "mdi-react/LinkedinBoxIcon";

import { Link } from "react-router-dom";

import "../Footer/Footer.scss";
import "./TeamFooter.css";

// Custom Components
import SocialLinks from "../SocialLinks";

const useStyles = makeStyles((theme) =>
  createStyles({
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    }
  })
);

export default function Footer(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }
  return (
    <div className="footer">
      <div className="footer-main footer-transparent">
        <div className="footer-button pb-10">
          <div className="footer-open-button bg-icon">
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show more"
            >
              <ExpandLessIcon className="icon-color footer-icon-size" />
            </IconButton>
          </div>

          <div className="footer-item social-container team-social-container team-footer-animation">
            <ul className="footer-social d-flex flex-wrap social-icon">
              <IconButton>
                <EmailIcon  target="_blank" className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              <IconButton>
                <PhoneIcon  target="_blank" className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              {props.teamData && props.teamData.fbLink && (
                <IconButton onClick={()=> window.open(props.teamData && props.teamData.fbLink, "_blank")}>
                  <FacebookBoxIcon className="icon-color icon-size footer-button-mobile" />
                </IconButton>
              )}
              {props.teamData && props.teamData.linkedIn && (
              <IconButton onClick={()=> window.open(props.teamData && props.teamData.linkedIn, "_blank")}>
                <LinkedinBoxIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              )}
              {props.teamData && props.teamData.twitterLink && (
              <IconButton onClick={()=> window.open(props.teamData && props.teamData.twitterLink, "_blank")}>
                <TwitterIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              )}
              {props.teamData && props.teamData.instagramLink && (              
              <IconButton onClick={()=> window.open(props.teamData && props.teamData.instagramLink, "_blank")}>
                <InstagramIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              )}
            </ul>
          </div>
          <div className="footer-chatbutton bg-icon-chat">
            <IconButton>
              <ChatBubble className={classes.button} className="icon-color" />
            </IconButton>
          </div>
        </div>
        {/* /////////////// */}
        <div className="footer-mobile">
          <div className="footer-open-button-mobile">
            <div className="bg-icon">
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="Show more"
              >
                <ExpandLessIcon className="icon-color footer-icon-size" />
              </IconButton>
            </div>
            <div className="bg-icon-chat">
              <IconButton>
                <ChatBubble className="icon-color" />
              </IconButton>
            </div>
          </div>
          <div className="footer-item social-container team-social-container team-footer-animation">
            <ul className="footer-social d-flex flex-wrap social-icon">
              <IconButton>
                <AccountPlusIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              <IconButton>
                <EmailIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              <IconButton>
                <PhoneIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              <IconButton>
                <FacebookBoxIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              <IconButton>
                <LinkedinBoxIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              <IconButton>
                <TwitterIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
              <IconButton>
                <InstagramIcon className="icon-color icon-size footer-button-mobile" />
              </IconButton>
            </ul>
          </div>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div className="footer-content footer-content-mobile-landscape">
            <div className="content content-mobile-landscape">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={3} lg={3} align="center">
                  <div className="footer-contact">
                    <div className="footer-title">CONTACT</div>
                    <div className="footer-item">
                      <List component="nav">
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <Link to="/contact" className="footer-link">
                          <div className="small-font-mobile">
                          Contact Us
                          </div>
                          </Link>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile">
                          Press
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile">
                          FAQ
                          </div>
                        </ListItem>
                      </List>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} align="center">
                  <div className="footer-about">
                    <div className="footer-title">ABOUT</div>
                    <div className="footer-item">
                      <List component="nav">
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <Link to="/about" className="footer-link">
                          <div className="small-font-mobile">
                          About Us
                          </div>
                          </Link>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile">
                           Our Process
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile"> Our
                          Groups
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile"> Our
                          Products
                          </div>
                        </ListItem>
                      </List>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} align="center">
                  <div className="footer-talent">
                    <div className="footer-title">TALENT</div>
                    <div className="footer-item">
                      <List component="nav">
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                          Freelance Designers
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                          Freelance Developers
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                          Freelance Finance Experts
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                          Freelance Product Managers
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                          Freelance Project Managers
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                          Specialized Talent
                          </div>
                        </ListItem>
                      </List>
                    </div>
                  </div>
                </Grid>
                {/* SOCIAL ICONS */}
                
                <SocialLinks />

                {/* HORIZONTAL LINE */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="footer-item footer-contact-detail-border"
                />

                  {/* BOTTOM FOOTER TEXT - LEFT */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    align="left"
                    className="footer-contact-detail contact-bottom-footer footer-bottom-grid-left"
                  >
                    <div className="footer-text subfooter-col1">
                      {/* <span className=""> */}
                      <div className="vlr">
                        <img
                          className="footer-logo footer-logo-img "
                          src="./image/nceptio_logo2.png"
                        />
                        </div>
                      {/* </span> */}
                      <wbr />
                      <span className="padding-10">
                        Taking the spark of an idea and
                        turning it into reality™
                      </span>
                    </div>
                  </Grid>

                  {/* BOTTOM FOOTER TEXT - RIGHT */}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}   
                    align="left"                 
                    className="footer-contact-detail contact-bottom-footer footer-bottom-grid-right"
                  >
                    <div className="footer-text subfooter-col2">
                      <span className="vlr">
                        &#169; 2015-2019 NCEPTIO, LLC. All Rights Reserved
                      </span>
                      <span className=" detail-footer-link">
                      
                        <a className="footer-link pr-10" href="#">
                          <Button size="small">
                          <div className="small-font-mobile">
                          Privacy Policy
                          </div>
                        
                          </Button>
                        </a>
                      
                        <a className="footer-link" href="#">
                          <Button size="small">
                          <div className="small-font-mobile">
                          Terms of service
                          </div>
                        
                          </Button>
                        </a>
                      </span>
                    </div>
                  </Grid>
              </Grid>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
