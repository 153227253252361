import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import posed, { PoseGroup } from 'react-pose';
import Home from "./components/home/Home";
import aboutus from "./components/aboutus/aboutus";
import work from "./components/work/work";
import contact from "./components/contact/contact";
import services from "./components/services/services";
import team from "./components/team/team";
import intro from "./components/intro/intro";
// import blog from "./components/blog/blog";
import blog from "./components/blogNew/blog";
import BlogPost from "./components/blog/BlogPost";
import press from "./components/press/press";
import faq from "./components/faq/faq";
import process from "./components/process/process";
import groups from "./components/groups/groups";
import products from "./components/products/products";
import CookieConsent from "react-cookie-consent";

import "./App.css";


const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 500, staggerChildren: 50  },
  exit: { opacity: 0, staggerChildren: 20, staggerDirection: -1 }
});

// const RouteContainer = posed.div({
//   enter: {
//     opacity: 1,
//     delay: 300,
//     beforeChildren: true
//   }
// });

const CustomApp = () => (
  <Route
    render={({ location }) => (
      <div id="site-container">              
        <div id="content-container">
        <CookieConsent
            location="bottom"
            // buttonText="Sure man!!"
            cookieName="myAwesomeCookieName2"
            style={{ 
              background: "#2B373B", 
              zIndex: "9999",
              fontFamily: "'Roboto', sans-serif !important",
              fontWeight: "600",
              fontSize: "16px",
            }}
            buttonStyle={{ 
              color: "#4e503b", 
              fontSize: "13px",
              margin: "5px",
              padding: "0px",
              borderRadius: "30px",
              background: "#6c7a89",
              color: "#fafaef",
              fontFamily: "'Roboto', sans-serif !important",
              fontWeight: "600",
              fontSize: "16px",
              height: "50px",
              width: "263px"
            }}
            expires={150}
            buttonClasses="btn-primary"
        >
            We use cookies to enhance the user experience.
        </CookieConsent>
          <PoseGroup>
            <RouteContainer key={location.pathname}>
              <Switch location={location}>
                <Route path="/" component={Home} exact  key="index" />
                <Route path="/about" component={aboutus}  key="about" />
                <Route path="/work" component={work}  key="work" />
                <Route path="/contact" component={contact}  key="contact" />
                <Route path="/services" component={services}  key="services" />
                <Route path="/team" component={team}  key="team" />
                <Route path="/intro" component={intro}  key="intro" />
                {/* <Route path="/blog" component={blog}  key="blog" /> */}
                <Route path="/blog" component={blog}  key="blog" />
                <Route path="/BlogPost" component={BlogPost}  key="BlogPost" />                
                {/* <Route path="/blog/:category" component={blog}  key="BlogCategory" /> */}
                <Route path="/press" component={press}  key="press" />
                <Route path="/faq" component={faq}  key="faq" />
                <Route path="/process" component={process}  key="process" />
                <Route path="/groups" component={groups}  key="groups" />
                <Route path="/products" component={products}  key="products" />
              </Switch>
            </RouteContainer>
          </PoseGroup>
        </div>
      </div>
    )}
  />
);

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <CustomApp />
      </BrowserRouter>
    );
  }
}
export default App;
