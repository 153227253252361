import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  link: {
    display: 'flex',
    color: '#6C7A89',
    textTransform: 'uppercase',
    fontSize: '2vh',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

// function handleClick(event) {
//   event.preventDefault();
//   alert('You clicked a breadcrumb.');
// }

export default function IconBreadcrumbs(props) {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/blog" className={classes.link}>
          <HomeIcon className={classes.icon} />        
        </Link>
        <Link
          color="inherit"
          // href="/getting-started/installation/"          
          className={classes.link}
        >         
         {props.BreadcrumbCategory}
        </Link>

        {!(props.isBlogTabs) &&                  
          <Typography color="textPrimary" className={classes.link}>        
            {props.BreadcrumbTitle}
          </Typography>                     
        }

      </Breadcrumbs>
    </Paper>
  );
}