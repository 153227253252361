import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import ScrollAnimation from 'react-animate-on-scroll';
import { isMobile, isTablet } from 'react-device-detect';
import WebCard from './WebCard';
import MobileCard from './MobileCard';
import Breadcrumb from "./Breadcrumbs";
import { posts } from "./data";
import Fade from "react-reveal/Fade";
import Footer from "../Footer/Footer";
import axios from 'axios';



const useStyles = makeStyles(theme => ({
  root: {
    // top: '80px',
    zIndex: '10',
    flexGrow: 1,
    width: '100%',
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  rootGrid: {
    // overflow: 'auto',
    // height: '32vh',
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  indicator: {
    backgroundColor: '#FAFAEF'
  }
}));

const BreadcrumbDetails = [
  {
    // category: "ALL"
  }
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ScrollableTabsButtonAuto(props) {
  const inputEl = useRef(null);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [blogTabs, setBlogTabs] = React.useState(true);
  const [appBarHeight, setAppBarHeight] = React.useState(0);
  const [displayComponent, setDisplayComponent] = React.useState(false);
  const [posts, setPosts] = React.useState(null);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function calculateAppBarHeight(ref) {
    const coords = ref && ref.getBoundingClientRect();
    if (coords) {
      setAppBarHeight(coords.height);
    }
  }
  const scrollToMyRef = () => {
    window.scrollTo({      
      top: props.sliderHeight + props.headerHeight - appBarHeight,
      behavior: 'smooth'
    })
    setDisplayComponent(true);
    props.handleHeader()
  }
  const onTabClick = tab => {
    // props.history.location.pathname = `blog/${tab}`
    // props.history.push(`blog/${tab}`)        
  };

  function onTabClickId() {
    axios('https://wp.nceptio.com/wp-json/wp/v2/posts').then((res) => {  
      console.log(res.data);
        
          setPosts(res.data)     
  })
  .catch((error) => {
  });
  }

  return (
    <div className={classes.root} onScroll={scrollToMyRef}>
      <AppBar position="static" color="default" ref={calculateAppBarHeight} style={{ top: (isMobile && displayComponent) ? 0 : `${props.headerHeight}px` }}>
        <Tabs
          value={value}
          onChange={handleChange}
          // indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          fullwidth={true}
          classes={{
            indicator: classes.indicator
          }}
        // className={isMobile && displayComponent ? "hideHeader" : "showHeader"}
        >
          <Tab label="ALL" {...a11yProps(0)} onClick={() => onTabClick('ALL')} />

          {props.categories.map((category, index) =>
          <Tab label={category.name} {...a11yProps(index + 1)} onClick={() => onTabClickId()} />   
                 
          )}
          {/* <Tab label="HEALTHCARE" {...a11yProps(1)} />
          <Tab label="ENERGY" {...a11yProps(2)} />
          <Tab label="COMMUNICATION" {...a11yProps(3)} />
          <Tab label="SOCIAL MEDIA" {...a11yProps(4)} />
          <Tab label="BLOCKCHAIN" {...a11yProps(5)} />
          <Tab label="FINTECH" {...a11yProps(6)} />
          <Tab label="REAL ESTATE" {...a11yProps(7)} />
          <Tab label="CONSTRUCTION" {...a11yProps(8)} /> */}
        </Tabs>
      </AppBar>

      <div className="blog-content-area" ref={inputEl} style={{
        top: ((isMobile) ? (displayComponent ? `calc(${appBarHeight}px + 10px)` : `calc(${props.sliderHeight + props.headerHeight + appBarHeight}px)`) : `calc(${props.sliderHeight}px - 60px)`)
      }}>
        <Fade bottom delay={600}>
          <div className="blog-details" id="scrolly-div">
            <div className="sticky-div">
            <Breadcrumb BreadcrumbCategory={BreadcrumbDetails[0].category} isBlogTabs={blogTabs} />

            <div className="blog-title-div">
              <h1 className="blog-title"> Latest Blogposts </h1>
            </div>
            </div>

            <TabPanel value={value} index={0}>
              }
              <div className={classes.rootGrid}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  wrap="wrap"
                  spacing={5}
                >
                  {posts && posts.map((post, index) =>
                  <div>
                 { console.log(post)}
                    <Grid item xs={12} sm={12} md={12} lg={6} component="a" href="/BlogPost" target="_blank">
                      {/* {(isMobile) && (!isTablet) ?
                        <ScrollAnimation animateIn="fadeIn" initiallyVisible={!index} animateOnce={true} scrollableParentSelector='#scrolly-div'>
                          <MobileCard
                          //  postImg={post.img} 
                           postCategory={post.categories} 
                           postTitle={post.title} 
                           postText={post.content} 
                           postAuthor={post.author} 
                          //  postAuthorImage={post.authorImage}
                           />
                        </ScrollAnimation>
                        :
                        <ScrollAnimation animateIn="fadeIn" initiallyVisible={index <= 1 && true} delay={index * 100} animateOnce={true} scrollableParentSelector='#scrolly-div'>
                          <WebCard isOdd={(index % 2 === 0) ? true : false} 
                          // postImg={post.img} 
                          postCategory={post.categories} 
                          postTitle={post.title} 
                          postText={post.content} 
                          postAuthor={post.author} 
                          // postAuthorImage={post.authorImage} 
                          />
                        </ScrollAnimation>
                      } */}
                    </Grid>
                    </div>
                  )}
                </Grid>
              </div>
            </TabPanel>

          {/* {props.categories.map((categoryIndex) => */}
            {/* <TabPanel value={value} index={categoryIndex + 1}>
             
              <div className={classes.rootGrid}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  wrap="wrap"
                  spacing={5}
                >
                  {posts.map((post, index) =>
                    <Grid item xs={12} sm={12} md={12} lg={6} component="a" href="/BlogPost" target="_blank">
                      {(isMobile) && (!isTablet) ?
                        <ScrollAnimation animateIn="fadeIn" initiallyVisible={!index} animateOnce={true} scrollableParentSelector='#scrolly-div'>
                          <MobileCard postImg={post.img} postCategory={post.category} postTitle={post.title} postText={post.text} postAuthor={post.author} postAuthorImage={post.authorImage} />
                        </ScrollAnimation>
                        :
                        <ScrollAnimation animateIn="fadeIn" initiallyVisible={index <= 1 && true} delay={index * 100} animateOnce={true} scrollableParentSelector='#scrolly-div'>
                          <WebCard isOdd={(index % 2 === 0) ? true : false} postImg={post.img} postCategory={post.category} postTitle={post.title} postText={post.text} postAuthor={post.author} postAuthorImage={post.authorImage} />
                        </ScrollAnimation>
                      }
                    </Grid>
                  )}
                </Grid>
              </div>
            </TabPanel> */}
           {/* )} */}


            {/* <TabPanel value={value} index={1}>
              <div className={classes.rootGrid}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  wrap="wrap"
                  spacing={5}
                >
                  {posts.map((post, index) =>
                    <Grid item xs={12} sm={6} component="a" href="/BlogPost" target="_blank">
                      {(isMobile) ?
                        <ScrollAnimation animateIn="fadeIn" initiallyVisible={!index} animateOnce={true} scrollableParentSelector='#scrolly-div'>
                          <MobileCard postImg={post.img} postCategory={post.category} postTitle={post.title} postText={post.text} postAuthor={post.author} postAuthorImage={post.authorImage} />
                        </ScrollAnimation>
                        :
                        <ScrollAnimation animateIn="fadeIn" delay={index * 200} initiallyVisible={index <= 1 && true} animateOnce={true} scrollableParentSelector='#scrolly-div'>
                          <WebCard isOdd={(index % 2 === 0) ? true : false} postImg={post.img} postCategory={post.category} postTitle={post.title} postText={post.text} postAuthor={post.author} postAuthorImage={post.authorImage} />
                        </ScrollAnimation>
                      }
                    </Grid>
                  )}
                </Grid>
              </div>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <div className={classes.rootGrid}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  wrap="wrap"
                  spacing={5}
                >
                  {posts.map((post, index) =>
                    <Grid item xs={12} sm={6} component="a" href="/BlogPost" target="_blank">
                      {(isMobile) ?
                        <ScrollAnimation animateIn="fadeIn" initiallyVisible={!index} animateOnce={true} scrollableParentSelector='#scrolly-div'>
                          <MobileCard postImg={post.img} postCategory={post.category} postTitle={post.title} postText={post.text} postAuthor={post.author} postAuthorImage={post.authorImage} />
                        </ScrollAnimation>
                        :
                        <ScrollAnimation animateIn="fadeIn" delay={index * 200} initiallyVisible={index <= 1 && true} animateOnce={true} scrollableParentSelector='#scrolly-div'>
                          <WebCard isOdd={(index % 2 === 0) ? true : false} postImg={post.img} postCategory={post.category} postTitle={post.title} postText={post.text} postAuthor={post.author} postAuthorImage={post.authorImage} />
                        </ScrollAnimation>
                      }
                    </Grid>
                  )}
                </Grid>
              </div>
            </TabPanel>

            <TabPanel value={value} index={3}>
              COMMUNICATION
            </TabPanel>

            <TabPanel value={value} index={4}>
              SOCIAL MEDIA
            </TabPanel>

            <TabPanel value={value} index={5}>
              BLOCKCHAIN
            </TabPanel>

            <TabPanel value={value} index={6}>
              FINTECH
            </TabPanel>

            <TabPanel value={value} index={7}>
              REAL ESTATE
            </TabPanel>

            <TabPanel value={value} index={8}>
              CONSTRUCTION
            </TabPanel> */}
          </div>
        </Fade>
      </div>
      {/* {displayComponent ?
        <Footer />
        :
        ''
      } */}
    </div>


  );
}
