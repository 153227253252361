import React from 'react'
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InstagramIcon from "mdi-react/InstagramIcon";
import TwitterIcon from "mdi-react/TwitterBoxIcon";
import LinkedinIcon from "mdi-react/LinkedinBoxIcon";
import FacebookIcon from "mdi-react/FacebookBoxIcon";

class SocialLinks extends React.PureComponent {
    render() {
        return (
            <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                className="footer-social-height footer-contact-detail"
            >
                <div className="footer-social contact-footer-new">
                    <div className="footer-title">SOCIAL</div>
                    <div className="footer-item social-container">
                    <ul className="footer-social d-flex flex-wrap social-icon">
                        <IconButton
                        button  target="_blank"
                        component="a"
                        href="https://www.linkedin.com/company/nceptio/about/"
                        >
                        <LinkedinIcon className="icon-color footer-button-mobile footer-mobile-button" />
                        </IconButton>

                        <IconButton
                        button  target="_blank"
                        component="a"
                        href="https://www.facebook.com/Nceptio-LLC-631809793984940/"
                        >
                        <FacebookIcon className="icon-color footer-button-mobile footer-mobile-button" />
                        </IconButton>

                        <IconButton
                        button  target="_blank"
                        component="a"
                        href=" https://twitter.com/nceptio_com"
                        >
                        <TwitterIcon className="icon-color footer-button-mobile footer-mobile-button" />
                        </IconButton>

                        <IconButton
                        button  target="_blank"
                        component="a"
                        href="https://www.instagram.com/nceptio_com/"
                        >
                        <InstagramIcon className="icon-color footer-button-mobile footer-mobile-button" />
                        </IconButton>
                    </ul>
                    </div>
                </div>
            </Grid>
        )
    }
}

export default SocialLinks;