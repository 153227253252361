import React, { useState, useEffect } from 'react';
import Header from '../header/Header';
import Grid from '@material-ui/core/Grid';
import "react-id-swiper/lib/styles/scss/swiper.scss";
import 'react-animated-slider/build/vertical.css';
import ScrollSnap from 'scroll-snap';
import uuid from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import posed from 'react-pose';

import SideMenu from '../SideMenu/SideMenu'
import Footer from '../Footer/Footer';
import './work.scss'
import WorkItem from './workItem';
import Div100vh from 'react-div-100vh';

const snapConfig = {
  scrollSnapDestination: "0% 100%",
  scrollTimeout: 300,
  scrollTime: 200
};

const Container = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 1 }
});

const menuData = [
  {
    path: "grid1"
  },
  {
    path: "grid2"
  },
  {
    path: "grid3"
  },
  {
    path: "grid4"
  },
  {
    path: "grid5"
  },
  {
    path: "grid6"
  },
];


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    flexGrow: 1,
    height: "100%",
    marginRight: "20px",
    marginLeft: "20px",
  },

  image: {
    position: "relative",
    height: "100%",
    width: "100%",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: "10px",
    border: "none",
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: "100%"
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15
      },
      "& $imageMarked": {
        opacity: 0
      },
      "& $imageTitle": {
        border: "4px solid currentColor"
      }
    }
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fafaef"
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "block",
    backgroundSize: "100% 100%",
    width: "100%",
    height: "100%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: "#fafaef",
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  }
}));

export default function Work(props) {

  const work = React.createRef();
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = props.location;  
  const landscapeFlag = window.matchMedia("(orientation: landscape)").matches;

  useEffect(() => {
    bindScrollSnap();
  });

  function bindScrollSnap() {
    const callback = () => {
      if (work.current) {
        const activeIndex = Math.round(
          work.current.scrollTop / work.current.offsetHeight);

        setActiveIndex(activeIndex);
      }
    };
    const element = work.current;
    const snapObject = new ScrollSnap(element, snapConfig);
    snapObject.bind(callback);
  }

  function pagination(index) {
    setActiveIndex(index);
  };

  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  return (
    <Container>
      <SideMenu isOpen={menuOpen} toggleMenu={toggleMenu} pathname={pathname} history={props.history}/>
      <div className="container">
        <Header title1="OUR" title2="WORK" page={pathname} toggleMenu={toggleMenu} />

        <Div100vh className="paginateWrapper">
          <ul>
            {menuData &&
              menuData.map((menuItem, index) => {
                return (
                  <li
                    key={uuid.v1()}
                    className={index === activeIndex ? "active" : ""}
                    onClick={() => pagination(index)}
                  >
                    <a href={`#${menuItem.path}`} />
                  </li>
                );
              })}
          </ul>
        </Div100vh>
        <Div100vh className="flex-center work-div">
          <div className="intro-section">
            <h1 className="intro-title fade-in">Experience</h1>
            <div className="we-are-text">
              <h2 className="sub-title">we have significant <b>experience</b> in:</h2>
            </div>
          </div>
          <div className="flex-grids work-div-flex-grid" ref={work}>
            <div className={classes.root} id="grid1">
              <Grid container spacing={1}>
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  left
                  backgroundImage={`url('./image/healthcare-1306x800.jpg')`}
                  title={`HEALTHCARE`}
                />
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  right
                  backgroundImage={`url('./image/energy-1306x800.jpg')`}
                  title={`ENERGY`}
                />
              </Grid>
            </div>
            <div className={classes.root} id="grid2">
              <Grid container spacing={1}>
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  left
                  backgroundImage={`url('./image/communication-1306x800-v2.jpg')`}
                  title={`COMMUNICATIONS`}
                />
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  right
                  backgroundImage={`url('./image/social-media-1306x800.jpg')`}
                  title={`SOCIAL MEDIA`}
                />
              </Grid>
            </div>
            <div className={classes.root} id="grid3">
              <Grid container spacing={1}>
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  left
                  backgroundImage={`url('./image/fintech-1306x800.jpg')`}
                  title={`FINTECH`}
                />
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  right
                  backgroundImage={`url('./image/blockchain-1306x800.jpg')`}
                  title={`BLOCKCHAIN`}
                />
              </Grid>
            </div>
            <div className={classes.root} id="grid4">
              <Grid container spacing={1}>
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  left
                  backgroundImage={`url('./image/real-estate-1306x800.jpg')`}
                  title={`REAL ESTATE`}
                />
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  right
                  backgroundImage={`url('./image/construction-1306x800.jpg')`}
                  title={`CONSTRUCTION`}
                />
              </Grid>
            </div>
            <div className={classes.root} id="grid5">
              <Grid container spacing={1}>
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  left
                  backgroundImage={`url('./image/logistics-1306x800.jpg')`}
                  title={`LOGISTICS`}
                />
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  right
                  backgroundImage={`url('./image/compliance-1306x800.jpg')`}
                  title={`COMPLIANCE`}
                />
              </Grid>
            </div>
            <div className={classes.root} id="grid6">
              <Grid container spacing={1}>
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  left
                  backgroundImage={`url('./image/entertainment-1306x800-alt.jpg')`}
                  title={`ENTERTAINMENT`}
                />
                <WorkItem
                  landscapeFlag={landscapeFlag}
                  right
                  backgroundImage={`url('./image/gaming-1306x800.jpg')`}
                  title={`GAMING`}
                />
              </Grid>
            </div>
          </div>
        </Div100vh>
        <Footer />
      </div>
    </Container>
  );
}