import React from "react";
import "./intro.scss";
import SplitText from 'react-pose-text';
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import posed from 'react-pose';

const Container = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 1 }
});


const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ wordIndex }) => wordIndex * 500
  }
};

const charPoses1 = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: 1000
  }
};

const charPoses2 = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ wordIndex }) => 1500 + wordIndex * 500

  }
};

const charPoses3 = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: 2500

  }
};

const charPoses4 = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ wordIndex }) => 4000 + wordIndex * 500

  }
};

class Intro extends React.Component {
  componentDidMount() {
    document.body.classList.add('og');
  }
  container = React.createRef();
  render() {


    return (


      <Container>
        <div className="container custom-container" ref={this.container} style={{
          overflow: "hidden"
        }}>
          <div className="we-are-text intro-flex">
            <h2>
              <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
                Taking the
          </SplitText>
            </h2>

            <h2>
              <b>
                <SplitText initialPose="exit" pose="enter" charPoses={charPoses1}>
                  &nbsp;spark
          </SplitText>
              </b>
            </h2>

            <h2>
              <SplitText initialPose="exit" pose="enter" charPoses={charPoses2}>
                &nbsp;of an
          </SplitText>
            </h2>

            <h2> <b>
              <SplitText initialPose="exit" pose="enter" charPoses={charPoses3}>
                &nbsp;idea &nbsp;
          </SplitText>
            </b>
            </h2>
          </div>

          <div className="text-center">
            <div className="hand-image">
              <Fade up delay={2500}>
                <img
                  src="./image/hand-1.png"
                />
              </Fade>
            </div>

            <div className="spark-img">
              <Zoom delay={3200}>
                <img
                  src="./image/LightVector.svg"
                />
                <img
                  src="./image/LightVector.svg"
                  className="image2"
                />
              </Zoom>
            </div>
          </div>

          <div className="bg-color fade-in-color">
            <span></span>
          </div>

          <div className="slide2 we-are-text">
            <h2>
              <SplitText initialPose="exit" pose="enter" charPoses={charPoses4}>
                and turning it into
          </SplitText>
            </h2>
          </div>

          <div className="bg-color-black puff-in-center">
            <span></span>
          </div>

          <h2 className="text-reality puff-in-center">
            reality
        </h2>

          <div className="img-phone1">
            <img
              src="./image/onsiteful-0.png"
              className="puff-in-center position-relative"
            />
            <Fade delay={7000} duration={500}>
              <img
                src="./image/onsiteful-1.png"
                className="img-2 position-absolute"
              />
            </Fade>
            <Fade delay={7800} duration={500}>
              <img
                src="./image/phone-GGP-no-reflection.svg"
                className="img-3 position-absolute"
              />
            </Fade>
          </div>

          <img
            src="./image/LightVector.svg"
            className="puff-in-center-image spark-img-2"
          />

          <Fade delay={8500}>
            <div className="logo-img">
              <div className="logo-image">
                <img
                  src="./image/NCEPTIO-ICON-WHITE.svg"
                />
              </div>
              <h2>
                NCEPTIO
          </h2>
            </div>
          </Fade>

          <Fade delay={9500}>
            <div className="bg-white">
              <span></span>
            </div>
          </Fade>

          <Fade delay={10500}>
            <div className="last-slide">
              <span></span>
            </div>
          </Fade>
          <div className="scale-logo">
            <img
              src="./image//NCEPTIO-ICON-WHITE.svg"
              className="puff-in-center-logo scale-img-logo"
            />
            <h2 className="puff-in-center-text">
              NCEPTIO
          </h2>
            <img src="./image/LightVector.svg"
              className="puff-in-center-img light-image"
            />

          </div>
        </div>
      </Container>
    );
  }
}
export default Intro;
