import React from "react";
import Fade from "react-reveal/Fade";
import Div100vh from "react-div-100vh";
import VerticalTabs from "./tabs";

export default function CoreService(props) {
    return (
        <>
            <Div100vh
                className="core1 scroll-item v-center-flex intro-parent-section"
                id="core1"
            >
                <Fade delay={2000} duration={3000}>
                    <div className="intro-section">
                        <h1 className="intro-title">Core</h1>
                    </div>
                </Fade>
                <div className="we-are-text about__text-desc service__text-desc about-sub-text service-sub-text-justify-center">
                    <Fade delay={3000} duration={3000}>
                        <h2 id="Approach-text1">
                            our <b> core strengths </b> include:
                        </h2>
                    </Fade>
                    <Fade delay={4000} duration={3000}>
                        <h2>
                            {/* {" "} */}
                            <b> management </b> services
                        </h2>
                    </Fade>
                    <Fade delay={5000} duration={3000}>
                        <h2>
                            <b> team </b> management
                        </h2>
                    </Fade>
                    <Fade delay={6000} duration={3000}>
                        <h2>
                            <b> project </b> management
                        </h2>
                    </Fade>
                    <Fade delay={7000} duration={3000}>
                        <h2>
                            <b> product </b> management
                        </h2>
                    </Fade>
                    <Fade delay={8000} duration={3000}>
                        <h2>
                            <b> infrastructure </b> management
                        </h2>
                    </Fade>
                </div>
            </Div100vh>

            <Div100vh
                className="core2 scroll-item v-center-flex intro-parent-section"
                id="core2"
            >
                <div className="intro-section">
                    <h1 className="intro-title">Core</h1>
                </div>
                <div className="we-are-text about__text-desc service__text-desc about-sub-text service-sub-text-justify-center">
                <Fade delay={3000} duration={3000}>
                        <h2 id="Approach-text1">
                            our <b> core strengths </b> include:
                        </h2>
                    </Fade>
                    <Fade delay={4000} duration={3000}>
                    <h2>
                        <b> design </b> services
                    </h2>
                    </Fade>
                    <Fade delay={5000} duration={3000}>
                    <h2>
                        <b> graphic </b> design
                    </h2>
                    </Fade>
                    <Fade delay={6000} duration={3000}>
                    <h2>
                        <b> ux </b> design
                    </h2>
                    </Fade>
                    <Fade delay={7000} duration={3000}>
                    <h2>
                        <b> ui </b> design
                    </h2>
                    </Fade>
                    <Fade delay={8000} duration={3000}>
                    <h2>
                        <b> animation </b>
                    </h2>
                    </Fade>
                </div>
            </Div100vh>

            <Div100vh
                className="core2 scroll-item v-center-flex intro-parent-section"
                id="core3"
            >
                <div className="intro-section">
                    <h1 className="intro-title">Core</h1>
                </div>
                <div className="we-are-text about__text-desc service__text-desc about-sub-text service-sub-text-justify-center cards-style translate-div">
                    <h2 id="Approach-text1">
                        our <b> core strengths </b> include:
                    </h2>
                    <VerticalTabs />
                </div>                
            </Div100vh>
        </>
    )
}
