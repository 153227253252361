import React from "react";
import "./Home.css";
import Header from "../header/Header";
import { Link } from "react-router-dom";
import SideMenu from '../SideMenu/SideMenu';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: true,       
    };
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen
    }))
  }

  render() {
    const { isMenuOpen } = this.state;
    return (
      <>
      <SideMenu isOpen={isMenuOpen} toggleMenu={this.toggleMenu} pathname={this.props.location.pathname} history={this.props.history} />
      <div className="container">
        <Header toggleMenu={this.toggleMenu}/>
      </div>
      </>
    );
  }
}
export default Home;
