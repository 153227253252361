
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CoreCard from './CoreCard';
import Grid from "@material-ui/core/Grid";
import Fade from "react-reveal/Fade";
import { management, design, businessAnalysis, systems, onlineMarketing, dataManagement, dataAnalysis, software, icons } from "./data"

const TabIcons = (props) => {

    return (
        <img src={icons[props.name].img} alt={icons[props.name]} height="40" width="40" />
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <div style={{
                height: '100%',
                overflow: 'auto',
            }}>
                {children}
            </div>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // height: 450,
        marginTop: '70px',
        color: '#FAFAEF',
        width: '95vw',
        ['@media (max-width:767px)']: {
            // height: 300,
            width: '100%',
            marginTop: '20px',
            height: '40%'
        }
    },
    indicator: {
        backgroundColor: '#FAFAEF',
        width: '4px'
    },
    tabFont: {
        fontSize: '1rem',
        ['@media (max-width:767px)']: {
            minHeight: '30px',
            fontSize: '2rem',
        }
    },
    rootGrid: {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        height: '100%',
        paddingTop: '20px'
    },
    tabHeight: {
        height: '100%'
    }
}));

export default function VerticalTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <div className={classes.root}>

            <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                variant="scrollable"
                scrollButtons={'on'}
                className={classes.tabs}
                classes={{
                    indicator: classes.indicator
                }}
            >
                <Tab className={classes.tabFont} icon={<TabIcons name="managementServices" />} label="Management" {...a11yProps(0)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="designServices" />} label="Design" {...a11yProps(1)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="businessAnalysis" />} label="Business Analysis" {...a11yProps(2)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="systemServices" />} label="Systems" {...a11yProps(3)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="marketingServices" />} label="Online Marketing" {...a11yProps(4)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="dataManagement" />} label="Data Management" {...a11yProps(5)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="dataAnalyis" />} label="Data Analysis" {...a11yProps(6)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="softwareDevelopment" />} label="Software Development" {...a11yProps(7)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="softwareService" />} label="Software as a Service" {...a11yProps(8)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="productPartnership" />} label="Product partnership" {...a11yProps(9)} />
                <Tab className={classes.tabFont} icon={<TabIcons name="productSupport" />} label="Product support center 24/7" {...a11yProps(10)} />

            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabHeight}>
                <div className={classes.rootGrid}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="wrap"
                        spacing={3}
                    >
                        {management.map((service, index) =>
                            <Grid item xs={6} sm={3} md={3} lg={2}>
                                <Fade delay={index * 500}>
                                    <CoreCard serviceImage={service.img} serviceText={service.text} />
                                </Fade>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabHeight}>
                <div className={classes.rootGrid}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="wrap"
                        spacing={3}
                    >
                        {design.map((service, index) =>
                            <Grid item xs={4} sm={3} md={3} lg={2}>
                                <Fade delay={index * 500}>
                                    <CoreCard serviceImage={service.img} serviceText={service.text} />
                                </Fade>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabHeight}>
                <div className={classes.rootGrid}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="wrap"
                        spacing={3}
                    >
                        {businessAnalysis.map((service, index) =>
                            <Grid item xs={4} sm={3} md={3} lg={2}>
                                <Fade delay={index * 500}>
                                    <CoreCard serviceImage={service.img} serviceText={service.text} />
                                </Fade>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </TabPanel>
            <TabPanel value={value} index={3} className={classes.tabHeight}>
                <div className={classes.rootGrid}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="wrap"
                        spacing={3}
                    >
                        {systems.map((service, index) =>
                            <Grid item xs={4} sm={3} md={3} lg={2}>
                                <Fade delay={index * 500}>
                                    <CoreCard serviceImage={service.img} serviceText={service.text} />
                                </Fade>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </TabPanel>
            <TabPanel value={value} index={4} className={classes.tabHeight}>
                <div className={classes.rootGrid}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="wrap"
                        spacing={3}
                    >
                        {onlineMarketing.map((service, index) =>
                            <Grid item xs={4} sm={3} md={3} lg={2}>
                                <Fade delay={index * 500}>
                                    <CoreCard serviceImage={service.img} serviceText={service.text} />
                                </Fade>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </TabPanel>
            <TabPanel value={value} index={5} className={classes.tabHeight}>
                <div className={classes.rootGrid}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="wrap"
                        spacing={3}
                    >
                        {dataManagement.map((service, index) =>
                            <Grid item xs={4} sm={3} md={3} lg={2}>
                                <Fade delay={index * 500}>
                                    <CoreCard serviceImage={service.img} serviceText={service.text} />
                                </Fade>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </TabPanel>
            <TabPanel value={value} index={6} className={classes.tabHeight}>
                <div className={classes.rootGrid}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="wrap"
                        spacing={3}
                    >
                        {dataAnalysis.map((service, index) =>
                            <Grid item xs={4} sm={3} md={3} lg={2}>
                                <Fade delay={index * 500}>
                                    <CoreCard serviceImage={service.img} serviceText={service.text} />
                                </Fade>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </TabPanel>
            <TabPanel value={value} index={7} className={classes.tabHeight}>
                <div className={classes.rootGrid}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        wrap="wrap"
                        spacing={3}
                    >
                        {software.map((service, index) =>
                            <Grid item xs={4} sm={3} md={3} lg={2}>
                                <Fade delay={index * 500}>
                                    <CoreCard serviceImage={service.img} serviceText={service.text} />
                                </Fade>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </TabPanel>
            <TabPanel value={value} index={8} className={classes.tabHeight}>

            </TabPanel>
            <TabPanel value={value} index={9} className={classes.tabHeight}>

            </TabPanel>
            <TabPanel value={value} index={10} className={classes.tabHeight}>

            </TabPanel>

        </div>
    );
}