import React from 'react';
import Grid from '@material-ui/core/Grid';
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        minWidth: 300,
        flexGrow: 1,
        height: "100%",
        marginRight: "20px",
        marginLeft: "20px",
    },

    image: {
        position: "relative",
        height: "100%",
        width: "100%",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "10px",
        border: "none",
        padding: 0,
        [theme.breakpoints.down("xs")]: {
            width: "100% !important", // Overrides inline-style
            height: "100%"
        },
        "&:hover, &$focusVisible": {
            zIndex: 1,
            "& $imageBackdrop": {
                opacity: 0.15
            },
            "& $imageMarked": {
                opacity: 0
            },
            "& $imageTitle": {
                border: "4px solid currentColor"
            }
        }
    },
    paper: {
        padding: theme.spacing(2),
        margin: "auto",
    },
    focusVisible: {},
    imageButton: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fafaef",
        "&:hover": {
            "& $imageTitle": {
                backgroundColor: "#00000075 !important"
            }
        }
    },
    imageSrc: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "block",
        backgroundSize: "100% 100%",
        width: "100%",
        height: "100%"
    },
    imageBackdrop: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        // backgroundColor: theme.palette.common.black,
        backgroundColor: 'black !important',
        opacity: 0.4,
        transition: theme.transitions.create("opacity")
    },
    imageTitle: {
        position: "relative",
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
            6}px`
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: "#fafaef",
        position: "absolute",
        bottom: -2,
        left: "calc(50% - 9px)",
        transition: theme.transitions.create("opacity")
    }
}));

export default function WorkItem(props) {
    const classes = useStyles();
    const { landscapeFlag, backgroundImage, title, ...rest } = props;
    return (
        <Grid item xs={landscapeFlag ? 6 : 12} md={landscapeFlag ? 6 : 12} lg={6} className="work-grid">
            <Fade  {...rest} delay={1000}>
                <ButtonBase
                    className={classes.image}
                    style={{
                        backgroundImage: backgroundImage,
                        // height: '85%'
                    }}
                >
                    <Paper className={classes.imageBackdrop}
                    >

                    </Paper>
                    <Paper className={classes.imageButton}>
                        <Typography
                            component="span"
                            variant="subtitle1"
                            color="inherit"
                            className={classes.imageTitle}
                        >
                            {title}
                            <span className={classes.imageMarked} />
                        </Typography>
                    </Paper>
                </ButtonBase>
            </Fade>
        </Grid>
    );
}